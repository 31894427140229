import React, { useEffect, useContext, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";
import { userLogin } from "../../config/api.service";
import { getAuth, setAuth } from "../../config/identity.service";
import Loading from "../../components/Loader.jsx";

function PagesLogin() {
	const navigate = useNavigate();
	const context = useContext(AppSettings);
	const [usertype, setUserType] = useState("gram-panchayat/pdo");
	const [errorMessage, setErrorMessage] = useState(false);
	const [successMessage, setSuccessMessage] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentClass("p-0");

		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass("");
		};
	}, []);

	const login = async (event) => {
		event.preventDefault();
		try {
			setLoading(true);
			const userDetails = await userLogin(
				event.target.username.value,
				event.target.password.value,
				usertype
			);
			setLoading(false);
			if (userDetails?.authJwtToken) {
				setErrorMessage(false);
				setSuccessMessage(true);
				setAuth(userDetails);
				navigate("/dashboard");
			} else {
				setSuccessMessage(false);
				setErrorMessage(true);
			}
		} catch (e) {
			console.log(e.response);
		}
	};

	function handleChange(e) {
		const { nodeName, value } = e.target;
		if (nodeName === "INPUT") {
			setUserType(value);
		}
	}

	return (
		<div>
			<div className="d-flex justify-content-between">
				<img src="/leftgandhi.jpg" height="300" width="300" alt="" />
				<div className="text-center mt-5">
					<h2>
						ಮಹಾತ್ಮ ಗಾಂಧಿ ರಾಷ್ಟ್ರೀಯ ಗ್ರಾಮೀಣ ಉದ್ಯೋಗ ಖಾತರಿ ಯೋಜನೆಯ ಕ್ರಿಯಾ ಯೋಜನೆ
					</h2>
					<h2>ಉಡುಪಿ ಜಿಲ್ಲಾ ಪಂಚಾಯತ್</h2>
				</div>

				<img src="/rightgnadhi.jpg" height="300" width="300" alt="" />
			</div>

			<div className="d-flex justify-content-center">
				{loading === true ? <Loading /> : null}

				<div className="m-5 login-content border p-3">
					<form onSubmit={login}>
						<h1 className="text-center">Sign In</h1>
						<div className="text-white text-opacity-50 text-center mb-4">
							For your protection, please verify your identity.
						</div>
						{errorMessage && (
							<div className="text-center alert alert-danger">
								Invalid Credentials
							</div>
						)}
						{successMessage && (
							<div className="text-center alert alert-success">
								Login Successful
							</div>
						)}
						<div onChange={handleChange}>
							<>
								<div class="form-check form-check-inline mb-2">
									<input
										class="form-check-input"
										type="radio"
										name="user_type"
										value="gram-panchayat/pdo"
										// checked
									/>
									<label class="form-check-label" for="gram-panchayat/pdo">
										Gram Panchayat PDO
									</label>
								</div>
								<div class="form-check form-check-inline mb-2">
									<input
										class="form-check-input"
										type="radio"
										name="user_type"
										value="gram-panchayat/president"
									/>
									<label
										class="form-check-label"
										for="gram-panchayat/president"
									>
										Gram Panchayat President
									</label>
								</div>
							</>
							<>
								<div class="form-check form-check-inline mb-2">
									<input
										class="form-check-input"
										type="radio"
										name="user_type"
										value="taluk-panchayat/entry-worker"
									/>
									<label
										class="form-check-label"
										for="taluk-panchayat/entry-worker"
									>
										Taluk Panchayat Verifier
									</label>
								</div>
								<div class="form-check form-check-inline mb-2">
									<input
										class="form-check-input"
										type="radio"
										name="user_type"
										value="taluk-panchayat/eo"
									/>
									<label class="form-check-label" for="taluk-panchayat/eo">
										Taluk Panchayat EO
									</label>
								</div>
							</>
							<>
								<div class="form-check form-check-inline mb-2">
									<input
										class="form-check-input"
										type="radio"
										name="user_type"
										value="zilla-panchayat/entry-worker"
									/>
									<label
										class="form-check-label"
										for="zilla-panchayat/entry-worker"
									>
										Zilla Panchayat Verifier
									</label>
								</div>
								<div class="form-check form-check-inline mb-4">
									<input
										class="form-check-input"
										type="radio"
										name="user_type"
										value="zilla-panchayat/ceo"
									/>
									<label class="form-check-label" for="zilla-panchayat/ceo">
										Zilla Panchayat CEO
									</label>
								</div>
							</>
						</div>

						<div className="mb-3">
							<label className="form-label">
								Username <span className="text-danger">*</span>
							</label>
							<input
								type="text"
								className="form-control form-control-lg bg-white bg-opacity-5"
								placeholder=""
								name="username"
							/>
						</div>
						<div className="mb-3">
							<div className="d-flex">
								<label className="form-label">
									Password <span className="text-danger">*</span>
								</label>
								<div
									role="button"
									onClick={() => navigate("/forgotpassword")}
									className="ms-auto text-decoration-none text-opacity-50"
								>
									Forgot password?
								</div>
							</div>
							<input
								type="password"
								className="form-control form-control-lg bg-white bg-opacity-5"
								placeholder=""
								name="password"
							/>
						</div>
						<button
							type="submit"
							className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3"
						>
							Sign In
						</button>
					</form>
				</div>
			</div>
		</div>
	);
}

export default PagesLogin;
