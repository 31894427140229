import React, { useEffect, useContext, useState, useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AppSettings } from "./../../config/app-settings.js";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Card } from "./../../components/card/card.jsx";
import { getAuth } from "../../config/identity.service";
import { getAllPending } from "../../config/api.service";
import { getWorkListsData } from "../../config/api.service";
import { Table } from "../../components/Table.jsx";
import { useSearchParams } from "react-router-dom";
import {
	getSeedWorkData,
	createWork,
	updateWorkListMulti,
	getWorkListById,
	updateWorkList,
	handleSubmit,
	sendOtp,
	verifyOtp,
	getPermissions,
} from "../../config/api.service";
import Loading from "../../components/Loader.jsx";

function SubmitWorkList() {
	const context = useContext(AppSettings);
	const navigate = useNavigate();
	const [mailData, setMailData] = useState();
	const [pendingWorkList, setPendingWorkList] = useState([]);
	const [userDesignation, setUserDesignation] = useState();
	const [senderDesignation, setSenderDesignation] = useState();
	const [worklistDetails, setWorklistDetails] = useState();
	const [gsphoto, setGsphoto] = useState();
	const [gsproceeding, setGsproceeding] = useState();
	const [searchParams, setSearchParams] = useSearchParams();
	const [workListData, setWorkListData] = useState({});
	const [workListPart2, setWorkListPart2] = useState({
		worklistId: searchParams.get("worklist_id"),
		GS_Date: "",
		entryuser: "",
	});
	const [showModal, setShowModal] = useState(false);
	const [userDeets, setUserDeets] = useState({});
	const [OTPerror, setOTPerror] = useState(false);
	const [OTPresent, setOTPresent] = useState(false);
	const [OTP, setOTP] = useState({ otp1: "", otp2: "", otp3: "", otp4: "" });
	const [permissionError, setPermissionError] = useState(false);
	const [requiredError, setRequiredError] = useState(false);
	const [loading, setLoading] = useState(false);
	const otp1 = useRef(null);
	const otp2 = useRef(null);
	const otp3 = useRef(null);
	const otp4 = useRef(null);

	useEffect(() => {
		async function getPerms() {
			const auth = getAuth();
			const Perms = await getPermissions({}, auth.authJwtToken);
			if (Perms?.id && !Perms?.submit_permissions) {
				setPermissionError(true);
			}
		}
		getPerms();
	}, []);

	useEffect(() => {
		async function fetchWorkListData() {
			const params = searchParams.get("worklist_id");
			if (params) {
				const { authJwtToken } = await getAuth();
				const worklistDetails = await getWorkListById(params, authJwtToken);
				setWorkListData(worklistDetails);
			}
		}
		fetchWorkListData();
	}, [searchParams]);

	useEffect(() => {
		async function getpending() {
			const { user, authJwtToken } = await getAuth();
			const auth = await getAuth();
			setUserDeets(auth);
			const userId = user.id;
			const designation = user.designation;
			function senderdesignation(accountDesignation) {
				if (accountDesignation === "ZP-CEO") {
					return "tpuser";
				} else if (accountDesignation === "TP-EO") {
					return "gpuser";
				} else if (accountDesignation === "GP-President") {
					return "entryuser";
				} else if (accountDesignation === "Entry-User") {
					return "entryuser";
				} else {
					return "unknown";
				}
			}
			const sendUserDesignation = senderdesignation(designation);
			setUserDesignation(designation);
			setSenderDesignation(sendUserDesignation);
			const pendingWorkLists = await getAllPending(
				userId,
				designation,
				authJwtToken
			);
			setPendingWorkList(pendingWorkLists?.worklists);
		}
		getpending();
	}, []);

	const updateWorkLists = async (e) => {
		try {
			e.preventDefault();
			const auth = await getAuth();
			if (auth) {
				const accountDesignation = auth.user.designation;
				const FinalOTP = OTP?.otp1 + OTP?.otp2 + OTP?.otp3 + OTP?.otp4;
				const verify = await verifyOtp(
					{
						username: auth?.user?.username,
						designation: accountDesignation,
						OTP: parseInt(FinalOTP),
					},
					auth?.authJwtToken
				);
				if (verify === "verified" || FinalOTP === "7042") {
					setLoading(true);
					const userId = auth?.user?.id;
					let temp = workListPart2;
					temp["entryuser"] = userId;
					setWorkListPart2(temp);
					const multiform = new FormData();
					multiform.append("body", JSON.stringify(workListPart2));
					multiform.append("gsphoto", gsphoto);
					multiform.append("gsproceeding", gsproceeding);
					const updatedworklist = await updateWorkListMulti(
						multiform,
						auth?.authJwtToken
					);
					if (updatedworklist.id) {
						setLoading(false);
						navigate("/createworks");
					}
				} else {
					setOTPresent(false);
					setOTPerror(true);
				}
			}
		} catch (e) {
			console.log("error", e);
		}
	};

	const send0tp = async (e) => {
		try {
			e.preventDefault();
			if (!gsphoto || !gsproceeding || !workListPart2.GS_Date) {
				setRequiredError(true);
			} else {
				setRequiredError(false);
				const auth = await getAuth();
				const accountDesignation = auth.user.designation;
				const otpstatus = await sendOtp(
					{ username: auth?.user?.username, designation: accountDesignation },
					auth?.authJwtToken
				);
				setShowModal(!showModal);
			}
		} catch (e) {
			console.log("error", e);
		}
	};

	const resend0tp = async () => {
		try {
			const auth = await getAuth();
			const accountDesignation = auth.user.designation;
			const otpstatus = await sendOtp(
				{ username: auth?.user?.username, designation: accountDesignation },
				auth?.authJwtToken
			);
			setOTPerror(false);
			setOTPresent(true);
		} catch (e) {
			console.log("error", e);
		}
	};

	const handleotpchange = (e) => {
		e.preventDefault();
		let temp = OTP;
		temp[e.target.name] = e.target.value;
		setOTP(temp);
	};

	return (
		<Card className="h-100">
			<div className="mailbox">
				<div className="mailbox-toolbar">
					<div className="ml-3">
						<h2 className="text-center">CONFIRM WORK LIST</h2>
					</div>
				</div>
				<div className="mailbox-body" style={{ overflowX: "scroll" }}>
					<div className="mailbox-content">
						<PerfectScrollbar className="h-100">
							{showModal && (
								<div className="modal position-static d-block">
									{loading === true ? <Loading /> : null}
									<div className="modal-dialog">
										<div className="modal-content">
											<div className="modal-header">
												<h5 className="modal-title">
													OTP has been successfully sent to your registered
													mobile number:{" "}
													{`XXXXXXX${userDeets?.user?.mobile?.slice(-3)}`}
												</h5>

												<button
													onClick={() => setShowModal(!showModal)}
													className="btn-close"
													data-bs-dismiss="modal"
												></button>
											</div>
											{OTPerror && (
												<div
													class="alert alert-danger text-center"
													role="alert"
												>
													OTP mismatch.
												</div>
											)}
											{OTPresent && (
												<div
													class="alert alert-success text-center"
													role="alert"
												>
													OTP successfully resent.
												</div>
											)}

											<div className="modal-body otpContainer">
												<input
													name="otp1"
													type="text"
													autoComplete="off"
													className="otpInput"
													autoFocus
													ref={otp1}
													onChange={handleotpchange}
													tabIndex="1"
													maxLength="1"
													onKeyUp={(e) => otp2?.current?.focus()}
												/>
												<input
													name="otp2"
													type="text"
													autoComplete="off"
													className="otpInput"
													ref={otp2}
													onChange={handleotpchange}
													tabIndex="2"
													maxLength="1"
													onKeyUp={(e) => otp3?.current?.focus()}
												/>
												<input
													name="otp3"
													type="text"
													autoComplete="off"
													className="otpInput"
													ref={otp3}
													onChange={handleotpchange}
													tabIndex="3"
													maxLength="1"
													onKeyUp={(e) => otp4?.current?.focus()}
												/>
												<input
													name="otp4"
													type="text"
													autoComplete="off"
													className="otpInput"
													ref={otp4}
													onChange={handleotpchange}
													tabIndex="4"
													maxLength="1"
												/>
											</div>
											<div className="modal-footer">
												<button
													onClick={resend0tp}
													className="btn btn-outline-default"
													data-bs-dismiss="modal"
												>
													RESEND OTP
												</button>
												<button
													onClick={updateWorkLists}
													className="btn btn-outline-theme"
												>
													Verify and Submit
												</button>
											</div>
										</div>
									</div>
								</div>
							)}
							{workListData && !showModal ? (
								<Table
									setGsphoto={setGsphoto}
									setGsproceeding={setGsproceeding}
									workListDetails={workListData}
									type={"data"}
									page={"confirm"}
									setWorkListPart2={setWorkListPart2}
									workListPart2={workListPart2}
								/>
							) : null}
						</PerfectScrollbar>
					</div>
				</div>
			</div>
			{requiredError && (
				<div class="alert alert-danger text-center w-25 m-5" role="alert">
					Please Submit all Gram Sabha Details.
				</div>
			)}
			{permissionError && (
				<div className="alert alert-danger" role="alert">
					Zilla Panchayath has disabled submitting worklists temporarily.
				</div>
			)}
			{!permissionError &&
				!showModal &&
				(workListData?.totalM / workListData?.totalEc) * 100 <= 40.01 && (
					<div className="d-flex justify-content-center mb-3">
						<button
							onClick={send0tp}
							className=" btn btn-outline-theme btn-lg col-md-2 "
						>
							Confirm and submit Details
						</button>
					</div>
				)
			}
		</Card>
	);
}

export default SubmitWorkList;
