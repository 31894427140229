import React from "react";

function Loading() {
	return (
		<div
			class="d-flex justify-content-center"
			style={{
				display: "block",
				position: "fixed",
				margin: "auto 0",
				top: "0",
				left: "0",
				bottom: "0",
				right: "0",
				height: "100%",
				width: "100%",
				zIndex: "9999",
				backgroundColor: "rgba(255,255,255,0.5)",
			}}
		>
			<div
				style={{
					display: "block",
					position: "fixed",
					margin: "auto",
					top: "0",
					left: "0",
					bottom: "0",
					right: "0",
				}}
				class="spinner-border"
				role="status"
			>
				<span class="sr-only">Loading...</span>
			</div>
		</div>
	);
}

export default Loading;
