import React, { useEffect, useContext, useState, useRef } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";
import {
	getSeedWorkData,
	createWork,
	getWorkListById,
	updateWorkList,
	checkDeleteWork,
	handleSubmit,
	sendOtp,
	verifyOtp,
} from "../../config/api.service";
import { getAuth } from "../../config/identity.service";
import AsyncSelect from "react-select/async";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Card } from "./../../components/card/card.jsx";
import { useSearchParams } from "react-router-dom";
import { isGPOAuthenticated } from "../../config/identity.service";
import { Loader, Trash2 } from "react-feather";
import Loading from "../../components/Loader.jsx";

function CreateWorks() {
	const navigate = useNavigate();
	const context = useContext(AppSettings);
	const [agencies, setAgencies] = useState({});
	const [workCats, setWorkCats] = useState({});
	const [workType, setWorkType] = useState([]);
	const [workListData, setWorkListData] = useState({});
	const [singleworkType, setSingleWorkType] = useState({});
	const [estimatedCost, setEstimatedCost] = useState(0.0);
	const [worksData, setWorksData] = useState({
		FY: "2024-2025",
		work_category: "",
		work_type: "",
		isNRM: false,
		work_name: "",
		estimated_cost: estimatedCost,
		wage: 0.0,
		material: 0.0,
		wage_p: 0.0,
		material_p: 0.0,
		person_days: 0.0,
		implementing_agency: "",
		worklist: "",
		entryuser: "",
	});

	const [searchParams, setSearchParams] = useSearchParams();
	const [showWorkForm, setShowWorkForm] = useState(false);
	const [showError, setShowError] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setEstimatedCost(
			parseFloat(worksData?.wage) + parseFloat(worksData?.material)
		);
	}, [worksData]);

	useEffect(() => {
		setEstimatedCost(singleworkType?.estimated_cost);
	}, [singleworkType]);

	useEffect(() => {
		async function protectRoute() {
			const auth = getAuth();

			if (!isGPOAuthenticated(auth)) {
				navigate("/pages/login");
			}
		}
		protectRoute();
	}, []);

	useEffect(() => {
		async function fetchWorkListData() {
			const params = searchParams.get("worklist_id");
			if (params) {
				const { authJwtToken } = await getAuth();
				let temp = worksData;
				temp["worklist"] = params;
				setWorksData(temp);
				const worklistDetails = await getWorkListById(params, authJwtToken);
				setWorkListData(worklistDetails);
			}
		}
		fetchWorkListData();
	}, [searchParams]);

	useEffect(() => {
		const wage_percentage =
			(parseFloat(worksData.wage) / parseFloat(worksData.estimated_cost)) * 100;
		const material_percentage =
			(parseFloat(worksData.material) / parseFloat(worksData.estimated_cost)) *
			100;
		let temp = worksData;
		temp["wage_p"] = wage_percentage;
		temp["material_p"] = material_percentage;
		setWorksData(temp);
	}, [worksData.wage, worksData.material, worksData.estimated_cost]);

	function handleClick() {
		navigate(`/submitworklist?worklist_id=${worksData?.worklist}`);
	}

	useEffect(() => {
		async function fetchData() {
			try {
				const auth = getAuth();
				if (auth) {
					const seeds = await getSeedWorkData(auth?.authJwtToken);
					if (seeds.workcategories.length) {
						let arr = seeds.workcategories.map((el) => {
							return { value: el.id, label: el.name, worktypes: el.worktypes };
						});
						setWorkCats(arr);
					}
					if (seeds.implementingagencies.length) {
						let arr = seeds.implementingagencies.map((el) => {
							return { value: el.id, label: el.name };
						});
						setAgencies(arr);
					}
				}
			} catch (e) {
				console.log("error", e);
			}
		}
		fetchData();
	}, []);

	const createWorkFunc = async (e) => {
		try {
			setLoading(true);
			e.preventDefault();
			if (
				!worksData?.work_category?.length ||
				!worksData?.work_type?.length ||
				!worksData?.implementing_agency?.length
			) {
				setShowError(true);
				setLoading(false);
			} else {
				setLoading(true);
				setShowError(false);
				const user = await getAuth();
				let temp = worksData;
				temp["estimated_cost"] = estimatedCost;
				temp["wage_p"] =
					(parseFloat(temp?.wage) / parseFloat(temp?.estimated_cost)) * 100;
				temp["material_p"] =
					(parseFloat(temp?.material) / parseFloat(temp?.estimated_cost)) * 100;
				temp["entryuser"] = user?.user?.id;
				setWorksData(temp);
				const createdwork = await createWork(temp, user?.authJwtToken);
				let params = { worklist_id: createdwork?.worklist?.id };
				setSearchParams(params);
				setLoading(false);
				window.location.reload();
			}
		} catch (e) {
			console.log("error", e);
		}
	};

	const handleSelectChange = (ev) => {
		let tempData = worksData;
		tempData[ev.target.name] = ev.target.value;
		setWorksData(tempData);
	};

	const handleWorkTypesChange = (ev) => {
		let tempData = worksData;
		tempData["work_type"] = ev.target.name;
		setWorksData(tempData);
		let singleWorkType = workType.map((el) => {
			if (el.value === ev.target.id) {
				return el;
			}
		});
		const filtered = singleWorkType.filter(function (element) {
			return element !== undefined;
		});
		setSingleWorkType(filtered[0]);
		tempData.estimated_cost = filtered[0].estimated_cost;
		tempData.wage = filtered[0].wage_cost;
		tempData.material = filtered[0].material_cost;
		tempData.person_days = filtered[0].person_days;
		tempData.isNRM = filtered[0].isNRM;
		setWorksData(tempData);
	};

	const handleTextChange = (ev) => {
		let tempData = worksData;
		tempData[ev.target.name] = ev.target.value;
		console.log(tempData)
		setWorksData(tempData);
	};

	const handleNumberChange = (ev) => {
		let tempData = worksData;
		tempData[ev.target.name] = parseFloat(ev.target.value);
		setWorksData({ ...tempData });
	};

	const handleCalcChange = (ev) => {
		let tempData = worksData;

		tempData[ev.target.name] = ev.target.value;
		setWorksData(tempData);
	};

	const handleWorkCatChange = (ev) => {
		let tempData = worksData;
		tempData[ev.target.name] = ev.target.value;
		const worktypesdata = workCats.map((el) => {
			if (el.value == ev.target.id) {
				return el.worktypes;
			}
		});
		const filtered = worktypesdata.flat().filter(function (element) {
			return element !== undefined;
		});
		if (filtered.length) {
			let typearr = filtered.map((el) => {
				return { value: el?.id, label: el?.name, ...el };
			});
			setWorkType(typearr);
		}
		setWorksData(tempData);
	};

	const filterUserTypes = (inputValue) => {
		let filterData = worksData.filter((c) => {
			return c.label.toLowerCase().includes(inputValue.toLowerCase());
		});

		return filterData;
	};

	const loadOptions = (data, callback) => {
		setTimeout(() => {
			callback(filterUserTypes(data.inputVal));
		}, 1000);
	};

	const deleteWork = async (workId) => {
		const auth = getAuth();
		setLoading(true);
		await checkDeleteWork({ workId }, auth?.authJwtToken);
		setLoading(false);
		window.location.reload();
	};

	const styles = {
		container: (provided, state) => ({
			...provided,
			padding: 2,
			color: state.isFocused ? "blue" : "red",
		}),
		control: (base) => ({
			...base,
			border: 0,
			boxShadow: "none",
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused ? "#007bff" : null,
			color: state.isFocused ? "white" : null,
			zIndex: "999999",
		}),
	};

	return (
		<div>
			{/* <h2 className="text-center">Create Work List</h2> */}
			<Card className="h-100">
				{loading === true ? <Loading /> : null}
				<div className="mailbox">
					<div className="mailbox-toolbar flex justify-content-between">
						<button
							onClick={() => setShowWorkForm(!showWorkForm)}
							className="btn btn-outline-theme btn-lg mb-3 col-md-2"
						>
							{showWorkForm ? "clear work" : "+ Add new work"}
						</button>
						{workListData && workListData?.works?.length > 0 && (
							<button
								onClick={handleClick}
								className="btn btn-outline-theme btn-lg mb-3 col-md-2"
							>
								Confirm Details
							</button>
						)}
					</div>
					<div className="ml-3">
						<h2 className="text-center">CREATE WORK LIST</h2>
					</div>
					<div className="mailbox-body">
						<div className="mailbox-sidebar">
							<PerfectScrollbar
								className="h-100"
								options={{ suppressScrollX: true }}
							>
								<div className="mailbox-list">
									{workListData && workListData?.works?.length > 0 ? (
										workListData.works.map((work, index) => (
											<div
												key={index}
												className={
													"mailbox-list-item border-bottom" +
													(work.unread ? " unread" : "") +
													(work.hasAttachment ? " has-attachment" : "")
												}
											>
												<div className="mailbox-message">
													<Link to="#" className="mailbox-list-item-link">
														<div className="mailbox-sender">
															<span className="mailbox-sender-name">
																{work.work_name}
															</span>
															<span className="mailbox-time">
																{work.implementing_agency}
															</span>
														</div>
														<div className="mailbox-title">
															{work.work_type}
														</div>
														<div className="mailbox-desc">
															{work.work_category}
														</div>
													</Link>
												</div>
												<div onClick={() => deleteWork(work.id)}>
													<Trash2 />
												</div>
											</div>
										))
									) : (
										<div
											className="mailbox-list-item"
											style={{ minWidth: "360px" }}
										>
											Please add a work
										</div>
									)}
								</div>
							</PerfectScrollbar>
						</div>
						<div className="mailbox-content d-none d-lg-block">
							<PerfectScrollbar className="h-100">
								<div className="m-5  d-flex justify-content-between">
									<div className=" login-content">
										{showWorkForm && (
											<form onSubmit={createWorkFunc}>
												<h2 className="text-center">Create Work</h2>
												<div className="text-white text-opacity-50 text-center mb-4">
													Add Work to a WorkList.
												</div>

												<div>
													<div className="mb-3">
														<label className="form-label">
															FY <span className="text-danger">*</span>
														</label>
														<div className="form-control form-control-lg bg-white bg-opacity-5">
															{" "}
															2024-2025
														</div>
														{/* <select name="FY" id="FY" className="form-control form-control-lg bg-white bg-opacity-5" onChange={handleTextChange}>
															<option value="2024-2025">2024-2025</option>
														</select> */}
													</div>
													<div className="mb-3">
														<label className="form-label">
															Work Category{" "}
															<span className="text-danger">*</span>
														</label>
														<AsyncSelect
															cacheOptions
															// defaultValue={{ label: groupdata.time_zone.value, value: groupdata.time_zone.id }}
															loadOptions={(ev, callback) =>
																loadOptions({ inputVal: ev }, callback)
															}
															defaultOptions={workCats}
															onChange={(val) =>
																handleWorkCatChange({
																	target: {
																		name: "work_category",
																		value: val.label,
																		id: val.value,
																	},
																})
															}
															className="form-control"
															name="work_category"
															styles={styles}
														/>
													</div>
													<div className="mb-3">
														<label className="form-label">
															Work Type <span className="text-danger">*</span>
														</label>
														<AsyncSelect
															cacheOptions
															// defaultValue={{ label: groupdata.time_zone.value, value: groupdata.time_zone.id }}
															loadOptions={(ev, callback) =>
																loadOptions({ inputVal: ev }, callback)
															}
															defaultOptions={workType}
															onChange={(val) =>
																handleWorkTypesChange({
																	target: {
																		name: "work_type",
																		value: val.label,
																		...val,
																	},
																})
															}
															className="form-control"
															name="work_type"
															styles={styles}
														/>
													</div>
													<div className="mb-3">
														<label className="form-label">
															Implementing Agency{" "}
															<span className="text-danger">*</span>
														</label>
														<AsyncSelect
															cacheOptions
															// defaultValue={{ label: groupdata.time_zone.value, value: groupdata.time_zone.id }}
															loadOptions={(ev, callback) =>
																loadOptions({ inputVal: ev }, callback)
															}
															defaultOptions={agencies}
															onChange={(val) =>
																handleSelectChange({
																	target: {
																		name: "implementing_agency",
																		value: val.label,
																	},
																})
															}
															className="form-control"
															name="implementing_agency"
															styles={styles}
														/>
													</div>
													<div className="mb-3">
														<div className="d-flex">
															<label className="form-label">
																Work Name <span className="text-danger">*</span>
															</label>
														</div>
														<input
															type="text"
															className="form-control form-control-lg bg-white bg-opacity-5"
															name="work_name"
															onChange={handleTextChange}
															required
														/>
													</div>
													<div className="mb-3">
														<div className="d-flex">
															<label className="form-label">
																Estimated Cost (in lakhs){" "}
																<span className="text-danger">*</span>
															</label>
														</div>
														<input
															type="number"
															className="form-control form-control-lg bg-white bg-opacity-5"
															key={singleworkType?.estimated_cost}
															// defaultValue={singleworkType?.estimated_cost}
															name="estimated_cost"
															onChange={handleNumberChange}
															step="0.00001"
															disabled
															value={estimatedCost}
														/>
													</div>
													<div className="mb-3">
														<div className="d-flex">
															<label className="form-label">
																Wage (in lakhs){" "}
																<span className="text-danger">*</span>
															</label>
														</div>
														<input
															type="number"
															className="form-control form-control-lg bg-white bg-opacity-5"
															key={singleworkType?.wage_cost}
															defaultValue={singleworkType?.wage_cost}
															name="wage"
															onChange={handleNumberChange}
															step="0.00001"
															required
														/>
													</div>
													<div className="mb-3">
														<div className="d-flex">
															<label className="form-label">
																Material (in lakhs){" "}
																<span className="text-danger">*</span>
															</label>
														</div>
														<input
															type="number"
															className="form-control form-control-lg bg-white bg-opacity-5"
															key={singleworkType?.material_cost}
															defaultValue={singleworkType?.material_cost}
															name="material"
															onChange={handleNumberChange}
															step="0.00001"
															required
														/>
													</div>
													<div className="mb-3">
														<div className="d-flex">
															<label className="form-label">Wage %</label>
														</div>
														<input
															className="form-control form-control-lg bg-white bg-opacity-5"
															name="wage_p"
															onChangeCapture={handleCalcChange}
															value={
																(parseFloat(worksData.wage) /
																	parseFloat(estimatedCost)) *
																100
															}
															required
														/>
													</div>
													<div className="mb-3">
														<div className="d-flex">
															<label className="form-label">Material %</label>
														</div>
														<input
															className="form-control form-control-lg bg-white bg-opacity-5"
															name="material_p"
															onChange={handleCalcChange}
															value={
																(parseFloat(worksData.material) /
																	parseFloat(estimatedCost)) *
																100
															}
															required
														/>
													</div>
													<div className="mb-3">
														<div className="d-flex">
															<label className="form-label">
																Person Days{" "}
																<span className="text-danger">*</span>
															</label>
														</div>
														<input
															type="number"
															className="form-control form-control-lg bg-white bg-opacity-5"
															key={singleworkType?.person_days}
															defaultValue={singleworkType?.person_days}
															name="person_days"
															onChange={handleNumberChange}
															required
														/>
													</div>

													{showError && (
														<div
															class="alert alert-danger text-center"
															role="alert"
														>
															Please make sure you have filled all fields
														</div>
													)}
													<button
														type="submit"
														className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3 col-md-2"
													>
														Add Work
													</button>
												</div>
											</form>
										)}
									</div>
								</div>
							</PerfectScrollbar>
						</div>
					</div>
				</div>
			</Card>
		</div>
	);
}

export default CreateWorks;
