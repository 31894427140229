import React, { useEffect, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AppSettings } from "./../../config/app-settings.js";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Card } from "./../../components/card/card.jsx";
import { getAuth } from "../../config/identity.service";
import { getWorkListById } from "../../config/api.service";
import { Table } from "../../components/Table";



function Pdfview() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [worklistDetails, setWorklistDetails] = useState();
    useEffect(() => {
        async function fetchWorkListData() {
            const params = searchParams.get("worklist_id");
            if (params) {
                const worklistDetails = await getWorkListById(params);
                setWorklistDetails(worklistDetails);
            }
        }
        fetchWorkListData();
    }, [searchParams]);
    return (
        <div type="application/pdf" id="Pdfview" style={{ backgroundColor: "white", height: "100vh" }}>
            <Table workListDetails={worklistDetails} />
        </div>
    )
}

export default Pdfview;
