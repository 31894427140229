import axios from "axios";

const server_url = process.env.REACT_APP_SERVER_URL;

const postWithoutAuth = async (body, endpoint) => {
	const headers = {
		"Content-Type": "application/json",
	};
	return await axios
		.post(`${server_url}/${endpoint}`, body, { headers })
		.then((response) => {
			if (response && response.data) {
				return response.data;
			}
		})
		.catch((error) => {
			return error?.response?.data;
		});
};

const postWithAuth = async (body, endpoint, token) => {
	const headers = {
		"Content-Type": "application/json",
		Authorization: token,
	};
	return await axios
		.post(`${server_url}/${endpoint}`, body, { headers })
		.then((response) => {
			if (response && response.data) {
				return response.data;
			}
		})
		.catch((error) => {
			return error?.response?.data;
		});
};

const postWithAuthMulti = async (body, endpoint, token) => {
	const headers = {
		"Content-Type": "multipart/form-data",
		Authorization: token,
	};
	return await axios
		.post(`${server_url}/${endpoint}`, body, { headers })
		.then((response) => {
			if (response && response.data) {
				return response.data;
			}
		})
		.catch((error) => {
			return error?.response?.data;
		});
};

const getWithAuth = async (body, endpoint, token) => {
	const headers = {
		"Content-Type": "application/json",
		Authorization: token,
	};
	return await axios
		.get(`${server_url}/${endpoint}`, { headers })
		.then((response) => {
			if (response && response.data) {
				return response.data;
			}
		});
};

const postWithoutAuthMulti = async (body, endpoint) => {
	const headers = {
		"Content-Type": "multipart/form-data",
	};
	return await axios
		.post(`${server_url}/${endpoint}`, body, { headers })
		.then((response) => {
			if (response && response.data) {
				return response.data;
			}
		})
		.catch((error) => {
			return error?.response?.data;
		});
};

const getWithoutAuth = async (body, endpoint) => {
	const headers = {
		"Content-Type": "application/json",
	};
	return await axios
		.get(`${server_url}/${endpoint}`, { headers })
		.then((response) => {
			if (response && response.data) {
				return response.data;
			}
		});
};

export const userLogin = async (username, password, type) => {
	const body = { username, password };
	const endpoint = `${type}/login`;
	return await postWithoutAuth(body, endpoint);
};

export const updateWorkListMulti = async (workListData, token) => {
	const body = workListData;
	const endpoint = `update-work-list`;
	return await postWithAuthMulti(body, endpoint, token);
};

export const updateWorkList = async (workListData, token) => {
	const body = workListData;
	const endpoint = `update-work-list`;
	return await postWithAuth(body, endpoint, token);
};

export const handleSubmit = async (submitdata, endp, token) => {
	const body = submitdata;
	const endpoint = `${endp}`;
	return await postWithAuth(body, endpoint, token);
};

export const createWork = async (worksData, token) => {
	const body = worksData;
	const endpoint = `create-work`;
	return await postWithAuth(body, endpoint, token);
};

export const getSeedWorkData = async (token) => {
	const endpoint = `get-all-works`;
	return await getWithAuth({}, endpoint, token);
};

export const getWorkListsData = async (workListId, token) => {
	const body = { workListId };
	const endpoint = `get-worklist`;
	return await postWithAuth(body, endpoint, token);
};

export const getAllPending = async (userId, designation, token) => {
	const body = { userId, designation };
	const endpoint = `get-all-pending`;
	return await postWithAuth(body, endpoint, token);
};

export const getAllSubmitted = async (userId, designation, token, fYear) => {
	const body = { userId, designation, fYear };
	const endpoint = `get-all-submitted`;
	return await postWithAuth(body, endpoint, token);
};

export const getWorkListById = async (workListId, token) => {
	const body = { workListId };
	const endpoint = `get-worklist`;
	return await postWithAuth(body, endpoint, token);
};

export const updateWork = async (workData, token) => {
	const body = workData;
	const endpoint = `update-work`;
	return await postWithAuth(body, endpoint, token);
};

export const getDashboardData = async (userId, designation, token) => {
	const body = { userId, designation };
	const endpoint = `get-dashboard-data`;
	return await postWithAuth(body, endpoint, token);
};

export const postWorkCategory = async (name, token) => {
	const body = { name };
	const endpoint = `create-work-category`;
	return await postWithAuth(body, endpoint, token);
};

export const postWorkType = async (workData, token) => {
	const body = workData;
	const endpoint = `create-work-type`;
	return await postWithAuth(body, endpoint, token);
};

export const deleteWorkType = async (workTypeId, token) => {
	const body = workTypeId;
	const endpoint = `delete-work-type`;
	return await postWithAuth(body, endpoint, token);
};

export const getWorkTypes = async (workCategoryId, token) => {
	const body = workCategoryId;
	const endpoint = `get-work-category`;
	return await postWithAuth(body, endpoint, token);
};

export const getSubordinates = async (userDetails, token) => {
	const body = userDetails;
	const endpoint = `get-subordinates`;
	return await postWithAuth(body, endpoint, token);
};

export const getAllZPUsers = async (token) => {
	const endpoint = `get-all-zp`;
	return await getWithAuth({}, endpoint, token);
};

export const getAllZPCaseUsers = async (token) => {
	const endpoint = `get-all-zp-case`;
	return await getWithAuth({}, endpoint, token);
};

export const getAllTPUsers = async (token) => {
	const endpoint = `get-all-tp`;
	return await getWithAuth({}, endpoint, token);
};

export const getAllTPCaseUsers = async (token) => {
	const endpoint = `get-all-tp-case`;
	return await getWithAuth({}, endpoint, token);
};

export const getAllGPUsers = async (token) => {
	const endpoint = `get-all-gp`;
	return await getWithAuth({}, endpoint, token);
};

export const getAllEntryUsers = async (token) => {
	const endpoint = `get-all-pdo`;
	return await getWithAuth({}, endpoint, token);
};

export const createUser = async (userData, token) => {
	const body = userData;
	const endpoint = `create-user`;
	return await postWithAuth(body, endpoint, token);
};

export const sendEmailRecovery = async (userData) => {
	const body = userData;
	const endpoint = `sendemail`;
	return await postWithAuth(body, endpoint);
};

export const changepassword = async (userData) => {
	const body = userData;
	const endpoint = `changepass`;
	return await postWithAuth(body, endpoint);
};
export const checkDeleteWork = async (workId, token) => {
	const body = workId;
	const endpoint = `check-delete-work`;
	return await postWithAuth(body, endpoint, token);
};
export const sendOtp = async (data, token) => {
	const body = data;
	const endpoint = `sendotp`;
	return await postWithAuth(body, endpoint, token);
};
export const verifyOtp = async (data, token) => {
	const body = data;
	const endpoint = `verifyotp`;
	return await postWithAuth(body, endpoint, token);
};

export const getUserAbstract = async (userId, designation, fYear, IA) => {
	const body = { userId, designation, fYear, IA };
	const endpoint = `get-user-abstract`;
	return await postWithoutAuth(body, endpoint);
};
export const getUserAbstractWithIA = async (userId, designation, IA, token, fYear) => {
	const body = { userId, designation, IA, fYear };
	const endpoint = `get-user-abstract-by-IA`;
	return await postWithAuth(body, endpoint, token);
};

export const getPermissions = async (data, token) => {
	const body = data;
	const endpoint = `getPermissions`;
	return await postWithAuth(body, endpoint, token);
};
export const createOrUpdatePermissions = async (data, token) => {
	const body = data;
	const endpoint = `createOrUpdatePermissions`;
	return await postWithAuth(body, endpoint, token);
};
export const updateUserData = async (data, token) => {
	const body = data;
	const endpoint = `update-user`;
	return await postWithAuth(body, endpoint, token);
};
export const getUsersAbove = async (data, token) => {
	const body = data;
	const endpoint = `getUsersAbove`;
	return await postWithAuth(body, endpoint, token);
};

export const getUser = async (data, token) => {
	const body = data;
	const endpoint = `get-user`;
	return await postWithAuth(body, endpoint, token);
};
