import React, { useEffect, useState } from "react";
import { getAuth } from "../../config/identity.service";
import {
	getAllEntryUsers,
	getAllGPUsers,
	getAllTPCaseUsers,
	getAllTPUsers,
	getAllZPCaseUsers,
	updateUserData,
	getSubordinates,
	getUser,
} from "../../config/api.service";
import AsyncSelect from "react-select/async";

function EditUser() {
	const [editUsers, setEditUsers] = useState([]);
	const [user, setUser] = useState({
		userId: "",
		designation: "",
		toBeUpdated: {},
	});
	// const [searchText, setSearchText] = useState("");
	const [subTPdata, setSubTPData] = useState([]);
	const [userType, setUserType] = useState("");
	const [subGPdata, setSubGPData] = useState([]);
	const [subPDOdata, setSubPDOData] = useState([]);
	const [userToUpdate, setUserToUpdate] = useState();

	useEffect(() => {
		async function getsubs() {
			const auth = getAuth();
			const subs = await getSubordinates(
				{
					userId: auth.user.id,
					designation: auth?.user?.designation,
				},
				auth?.authJwtToken
			);
			let arr = subs?.subordinates.map((el) => {
				return { value: el.designation, label: el.username, user_id: el.id };
			});
			if (auth?.user?.designation === "ZP-CEO") {
				setSubTPData(arr);
			} else if (auth?.user?.designation === "TP-EO") {
				setSubGPData(arr);
			} else if (auth?.user?.designation === "GP-President") {
				setSubPDOData(arr);
			}
		}
		getsubs();
	}, []);

	const handleSelectTPChange = async (ev) => {
		const { authJwtToken } = await getAuth();
		const subs = await getSubordinates(
			{
				userId: ev.target.user_id,
				designation: ev.target.value,
			},
			authJwtToken
		);
		const userId = ev.target.user_id;
		const designation = ev.target.value;
		if (userId && designation) {
			const userData = await getUser({ userId, designation }, authJwtToken);
			setUserToUpdate(userData);
		}
		let arr = subs?.subordinates.map((el) => {
			return { value: el.designation, label: el.username, user_id: el.id };
		});
		setSubGPData(arr);
	};

	const handleSelectGPChange = async (ev) => {
		const { authJwtToken } = await getAuth();
		const subs = await getSubordinates(
			{
				userId: ev.target.user_id,
				designation: ev.target.value,
			},
			authJwtToken
		);
		const userId = ev.target.user_id;
		const designation = ev.target.value;
		if (userId && designation) {
			const userData = await getUser({ userId, designation }, authJwtToken);
			setUserToUpdate(userData);
		}
		let arr = subs?.subordinates.map((el) => {
			return { value: el.designation, label: el.username, user_id: el.id };
		});
		setSubPDOData(arr);
	};

	const handleSelectPDOChange = async (ev) => {
		const { authJwtToken } = await getAuth();
		const subs = await getSubordinates(
			{
				userId: ev.target.user_id,
				designation: ev.target.value,
			},
			authJwtToken
		);
		const userId = ev.target.user_id;
		const designation = ev.target.value;
		if (userId && designation) {
			const userData = await getUser({ userId, designation }, authJwtToken);
			setUserToUpdate(userData);
		}
		// let arr = subs?.subordinates.map((el) => {
		// 	return { value: el.designation, label: el.username, user_id: el.id };
		// });
		// setSubGPData(arr);
	};

	async function handleChange(e) {
		const auth = getAuth();
		const { nodeName, value } = e.target;
		if (nodeName === "INPUT") {
			if (value === "Entry-User") {
				const users = await getAllEntryUsers(auth?.authJwtToken);
				setEditUsers(users);
			} else if (value === "GP-President") {
				const users = await getAllGPUsers(auth?.authJwtToken);
				setEditUsers(users);
			} else if (value === "TP-Entry") {
				const users = await getAllTPCaseUsers(auth?.authJwtToken);
				setEditUsers(users);
			} else if (value === "TP-EO") {
				const users = await getAllTPUsers(auth?.authJwtToken);
				setEditUsers(users);
			} else if (value === "ZP-Entry") {
				const users = await getAllZPCaseUsers(auth?.authJwtToken);
				setEditUsers(users);
			}
		}
	}

	const handleUserNameChange = (e) => {
		user.toBeUpdated.username = e.target.value;
	};

	const handleNameChange = (e) => {
		user.toBeUpdated.name = e.target.value;
	};

	const handleEmailChange = (e) => {
		user.toBeUpdated.email = e.target.value;
	};

	const handleLocationChange = (e) => {
		user.toBeUpdated.location = e.target.value;
	};

	const handleNumberChange = (e) => {
		user.toBeUpdated.mobile = e.target.value;
	};

	const handlePasswordChange = (e) => {
		user.toBeUpdated.password = e.target.value;
	};

	const updateUser = async (userId, designation) => {
		const auth = getAuth();
		const body = {
			userId,
			designation,
			toBeUpdated: user.toBeUpdated,
		};
		const update = await updateUserData(body, auth?.authJwtToken);
		window.location.reload();
	};


	const filterTPTypes = (inputValue) => {
		let filterData = subTPdata.filter((c) => {
			return c.label.toLowerCase().includes(inputValue.toLowerCase());
		});

		return filterData;
	};
	const filterGPTypes = (inputValue) => {
		let filterData = subTPdata.filter((c) => {
			return c.label.toLowerCase().includes(inputValue.toLowerCase());
		});

		return filterData;
	};
	const filterPDOTypes = (inputValue) => {
		let filterData = subTPdata.filter((c) => {
			return c.label.toLowerCase().includes(inputValue.toLowerCase());
		});

		return filterData;
	};

	const loadTPOptions = (data, callback) => {
		setTimeout(() => {
			callback(filterTPTypes(data.inputVal));
		}, 1000);
	};
	const loadGPOptions = (data, callback) => {
		setTimeout(() => {
			callback(filterGPTypes(data.inputVal));
		}, 1000);
	};
	const loadPDOOptions = (data, callback) => {
		setTimeout(() => {
			callback(filterPDOTypes(data.inputVal));
		}, 1000);
	};

	const styles = {
		container: (provided, state) => ({
			...provided,
			padding: 2,
			color: state.isFocused ? "blue" : "red",
			// zIndex: "999999",
		}),
		control: (base) => ({
			...base,
			border: 0,
			boxShadow: "none",
			// zIndex: "999999",
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused ? '#007bff' : null,
			color: state.isFocused ? 'white' : 'black',
			// zIndex: "999999",
		}),
	};

	return (
		<>
			<h1>Edit User</h1>
			<div className="">
				<label className="form-label">
					TP
				</label>
			</div>
			<AsyncSelect
				cacheOptions
				loadOptions={(ev, callback) =>
					loadTPOptions({ inputVal: ev }, callback)
				}
				defaultOptions={subTPdata}
				onChange={(val) =>
					handleSelectTPChange({
						target: {
							user_id: val.user_id,
							value: val.value,
							label: val.label,
						},
					})
				}
				className="form-control z-index"
				name="TP"
				styles={{ ...styles, zIndex: "3" }}
			/>

			<div className="">
				<label className="form-label">
					GP
					{/* <span className="text-danger">*</span> */}
				</label>
			</div>
			<AsyncSelect
				cacheOptions
				// defaultValue={{ label: groupdata.time_zone.value, value: groupdata.time_zone.id }}
				loadOptions={(ev, callback) =>
					loadGPOptions({ inputVal: ev }, callback)
				}
				defaultOptions={subGPdata}
				onChange={(val) =>
					handleSelectGPChange({
						target: {
							user_id: val.user_id,
							value: val.value,
							label: val.label,
						},
					})
				}
				className="form-control"
				name="GP"
				styles={{ ...styles, zIndex: "2" }}
			/>

			<div className="">
				<label className="form-label">
					PDO
					{/* <span className="text-danger">*</span> */}
				</label>
			</div>
			<AsyncSelect
				cacheOptions
				// defaultValue={{ label: groupdata.time_zone.value, value: groupdata.time_zone.id }}
				loadOptions={(ev, callback) =>
					loadPDOOptions({ inputVal: ev }, callback)
				}
				defaultOptions={subPDOdata}
				onChange={(val) =>
					handleSelectPDOChange({
						target: {
							user_id: val.user_id,
							value: val.value,
							label: val.label,
						},
					})
				}
				className="form-control"
				name="PDO"
				styles={{ ...styles, zIndex: "1" }}
			/>

			{userToUpdate ? (
				<form
					className="border mt-3 mb-3 px-3 py-3"
					// onSubmit={updateUser(editUser.id, editUser.designation)}
					key={userToUpdate.id}
				>
					<div className="mb3-row">
						<div className="mb3-row">
							<label htmlFor="username" className="col-sm-2 col-form-label">
								<strong>Username:</strong>
							</label>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									defaultValue={userToUpdate?.username}
									name="name"
									onChange={handleUserNameChange}
									required
								/>
							</div>
						</div>
						<div className="mb3-row">
							<label htmlFor="username" className="col-sm-2 col-form-label">
								<strong>Name:</strong>
							</label>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									defaultValue={userToUpdate?.name}
									name="name"
									onChange={handleNameChange}
									required
								/>
							</div>
						</div>
						<div className="mb3-row">
							<label htmlFor="username" className="col-sm-2 col-form-label">
								<strong>Email:</strong>
							</label>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									defaultValue={userToUpdate?.email}
									name="name"
									onChange={handleEmailChange}
									required
								/>
							</div>
						</div>
						{userToUpdate?.designation === "GP-President" ||
							userToUpdate?.designation === "TP-EO" ||
							userToUpdate?.designation === "ZP-CEO" ? (
							<div className="mb3-row">
								<label htmlFor="username" className="col-sm-2 col-form-label">
									<strong>Location:</strong>
								</label>
								<div className="col-sm-10">
									<input
										type="text"
										className="form-control"
										defaultValue={userToUpdate?.location}
										name="name"
										onChange={handleLocationChange}
										required
									/>
								</div>
							</div>
						) : null}
						<div className="mb3-row">
							<label htmlFor="username" className="col-sm-2 col-form-label">
								<strong>Mobile Number:</strong>
							</label>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									defaultValue={userToUpdate?.mobile}
									name="name"
									onChange={handleNumberChange}
									required
								/>
							</div>
						</div>
						<div className="mb3-row">
							<label htmlFor="username" className="col-sm-2 col-form-label">
								<strong>Password:</strong>
							</label>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									defaultValue=""
									name="name"
									onChange={handlePasswordChange}
									required
								/>
							</div>
						</div>
						<button
							type="button"
							className="btn btn-outline-theme btn-lg my-3"
							onClick={() =>
								updateUser(userToUpdate?.id, userToUpdate?.designation)
							}
						>
							Update User
						</button>
					</div>
				</form>
			) : null}
		</>
	);
}

export default EditUser;
