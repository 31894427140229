import React, { useEffect, useContext, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";
import { sendEmailRecovery } from "../../config/api.service";
import Loading from "../../components/Loader.jsx";

function ForgotPassword() {
	const navigate = useNavigate();
	const context = useContext(AppSettings);
	const [usertype, setUserType] = useState("Entry-User");
	const [errorMessage, setErrorMessage] = useState(false);
	const [successMessage, setSuccessMessage] = useState(false);
	const [successText, setSuccessText] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentClass("p-0");

		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass("");
		};

		// eslint-disable-next-line
	}, []);

	const sendEmail = async (event) => {
		event.preventDefault();
		try {
			setLoading(true);
			const userDetails = await sendEmailRecovery({
				designation: usertype,
				username: event.target.username.value,
			});
			setLoading(false);
			if (userDetails?.status) {
				setErrorMessage(false);
				setSuccessMessage(true);
				setSuccessText(userDetails?.status);
				navigate("/pages/login");
			} else {
				setSuccessMessage(false);
				setErrorMessage(true);
			}
		} catch (e) {
			console.log(e.response);
		}
	};

	function handleChange(e) {
		const { nodeName, value } = e.target;

		if (nodeName === "INPUT") {
			setUserType(value);
		}
	}

	return (
		<div className="m-5 d-flex justify-content-between">
			{loading === true ? <Loading /> : null}

			<div className="login-content border p-3">
				<form onSubmit={sendEmail}>
					<h1 className="text-center">Recover Password</h1>
					<div className="text-white text-opacity-50 text-center mb-4">
						Please enter your username,recovery email will be sent to your
						registered email ID.
					</div>
					{errorMessage && (
						<div className="text-center alert alert-danger">
							Invalid Username
						</div>
					)}
					{successMessage && (
						<div className="text-center alert alert-success">{successText}</div>
					)}
					<div onChange={handleChange}>
						<>
							<div class="form-check form-check-inline mb-2">
								<input
									class="form-check-input"
									type="radio"
									name="user_type"
									value="Entry-User"
								// checked
								/>
								<label class="form-check-label" for="gram-panchayat/pdo">
									Gram Panchayat PDO
								</label>
							</div>
							<div class="form-check form-check-inline mb-2">
								<input
									class="form-check-input"
									type="radio"
									name="user_type"
									value="GP-President"
								/>
								<label class="form-check-label" for="gram-panchayat/president">
									Gram Panchayat President
								</label>
							</div>
						</>
						<>
							<div class="form-check form-check-inline mb-2">
								<input
									class="form-check-input"
									type="radio"
									name="user_type"
									value="TP-Entry"
								/>
								<label
									class="form-check-label"
									for="taluk-panchayat/entry-worker"
								>
									Taluk Panchayat EO Case Worker
								</label>
							</div>
							<div class="form-check form-check-inline mb-2">
								<input
									class="form-check-input"
									type="radio"
									name="user_type"
									value="TP-EO"
								/>
								<label class="form-check-label" for="taluk-panchayat/eo">
									Taluk Panchayat EO
								</label>
							</div>
						</>
						<>
							<div class="form-check form-check-inline mb-2">
								<input
									class="form-check-input"
									type="radio"
									name="user_type"
									value="ZP-Entry"
								/>
								<label
									class="form-check-label"
									for="zilla-panchayat/entry-worker"
								>
									Zilla Panchayat CEO Case Worker
								</label>
							</div>
							<div class="form-check form-check-inline mb-4">
								<input
									class="form-check-input"
									type="radio"
									name="user_type"
									value="ZP-CEO"
								/>
								<label class="form-check-label" for="zilla-panchayat/ceo">
									Zilla Panchayat CEO
								</label>
							</div>
						</>
					</div>

					<div className="mb-3">
						<label className="form-label">
							Username <span className="text-danger">*</span>
						</label>
						<input
							type="text"
							className="form-control form-control-lg bg-white bg-opacity-5"
							placeholder=""
							name="username"
						/>
					</div>
					{/* <div className="mb-3">
						<div className="form-check">
							<input
								className="form-check-input"
								type="checkbox"
								id="customCheck1"
							/>
							<label className="form-check-label" htmlFor="customCheck1">
								Remember me
							</label>
						</div>
					</div> */}
					{!successMessage && (
						<button
							type="submit"
							className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3"
						>
							Send Email
						</button>
					)}
					{/* <div className="text-center text-white text-opacity-50">
						Don't have an account yet? <Link to="/pages/register">Sign up</Link>
						.
					</div> */}
				</form>
			</div>
		</div>
	);
}

export default ForgotPassword;
