import React, { useEffect, useState } from "react";
import { updateWork } from "../config/api.service";
import { getAuth } from "../config/identity.service";
import { Trash2 } from "react-feather";

export const TableRow = ({
	work,
	index,
	type,
	setWorkStatus,
	workStatus,
	length,
	pagetype,
	setRemarks,
	remarks,
}) => {
	// const [workStatus, setWorkStatus] = useState("approve");
	const [workStatusRemarks, setWorkStatusRemarks] = useState("");
	const [tpWorkerReport, setTpWorkReport] = useState("");
	const [zpWorkerReport, setZpWorkReport] = useState("");
	const [updatedStat, setUpdatedStat] = useState(false);
	const [workstatusArr, setWorkStatusArr] = useState(
		Array.from({ length }, () => "approve")
	);

	console.log(workstatusArr);
	const handleClick = async () => {
		const workId = work.id;
		const work_status = workStatus;
		const auth = await getAuth();
		let updatedStatus;
		if (auth?.user?.designation === "TP-Entry") {
			updatedStatus = await updateWork(
				{
					workId,
					toBeUpdated: {
						tp_case_worker_remarks: `${tpWorkerReport}\n (reviewed by: ${auth?.user?.username})`,
					},
				},
				auth?.authJwtToken
			);
			const temp = remarks;
			temp[index] = true;
			setRemarks(temp);
		} else if (auth?.user?.designation === "ZP-Entry") {
			updatedStatus = await updateWork(
				{
					workId,
					toBeUpdated: {
						zp_case_worker_remarks: `${zpWorkerReport}\n (reviewed by: ${auth?.user?.username})`,
					},
				},
				auth?.authJwtToken
			);
			const temp = remarks;
			temp[index] = true;
			setRemarks(temp);
		} else if (
			auth?.user?.designation !== "TP-Entry" &&
			auth?.user?.designation !== "ZP-Entry"
		) {
			updatedStatus = await updateWork(
				{
					workId,
					toBeUpdated: {
						work_status,
						work_status_remarks: `${workStatusRemarks}\n (rejected by: ${auth?.user?.username})`,
					},
				},
				auth?.authJwtToken
			);
		}
		if (updatedStatus?.affected === 1) {
			setUpdatedStat(true);
		}
	};

	const onStatusChange = (event) => {
		const value = event.target.value;
		let data = [...workstatusArr];
		data[index] = event.target.value;
		setWorkStatusArr(data);
		setWorkStatus(value);
	};

	const handleRemarkChange = (event) => {
		const value = event.target.value;
		setWorkStatusRemarks(value);
	};

	const handleTPCaseReport = (event) => {
		const value = event.target.value;
		setTpWorkReport(value);
	};

	const handleZPCaseReport = (event) => {
		const value = event.target.value;
		setZpWorkReport(value);
	};

	return (
		<tr key={index}>
			{/* <Trash2 /> */}
			<td>{index + 1}</td>
			<td>{work.FY}</td>
			<td className="col-md-1">{work.work_category}</td>
			<td>{work.work_type}</td>
			<td>{work.work_name}</td>
			<td>{work.estimated_cost}</td>
			<td>{work.wage}</td>
			<td>{work.material}</td>
			<td>{work.wage_p}</td>
			<td>{work.material_p}</td>
			<td>{work.person_days}</td>
			<td className="col-md-1">{work.implementing_agency}</td>
			{type !== "data" &&
				type !== "TP-Entry" &&
				type !== "ZP-Entry" &&
				pagetype !== "complete" &&
				work.work_status === "approve" && (
					<>
						<td>
							<div className="row">
								<div className="col-xl-12">
									<select
										className="form-select"
										defaultValue="pending"
										onChange={onStatusChange}
									>
										<option value="approve">APPROVE</option>
										<option value="reject">REJECT</option>
									</select>
								</div>
							</div>
						</td>
						{/* {workstatusArr[index] === "approve" && <td></td>} */}
						{workstatusArr[index] === "reject" && (
							<>
								<td>
									<textarea
										className="form-control"
										id="workrejectionnotes"
										rows="2"
										onChange={handleRemarkChange}
										required
									></textarea>
									{!updatedStat ? (
										<button
											type="button"
											className="btn btn-outline-theme mt-2 btn-lg d-block w-100 fw-500 mb-3"
											onClick={handleClick}
										>
											Confirm
										</button>
									) : (
										<div>updated</div>
									)}
								</td>
							</>
						)}
						{workstatusArr[index] === "approve" && (
							<>
								<td></td>
							</>
						)}
						{/* <td>
							<div className=" danger">{work.work_status_remarks}</div>
						</td> */}

						<td>{work?.tp_case_worker_remarks}</td>
						<td>{work?.zp_case_worker_remarks}</td>
					</>
				)}
			{type !== "data" &&
				type !== "TP-Entry" &&
				type !== "ZP-Entry" &&
				pagetype !== "complete" &&
				work?.work_status === "reject" && (
					<>
						<td>
							<div className="row">
								<div className=" danger">REJECTED</div>
							</div>
						</td>
						<td>
							<div className=" danger">{work.work_status_remarks}</div>
						</td>

						<td>{work?.tp_case_worker_remarks}</td>
						<td>{work?.zp_case_worker_remarks}</td>
					</>
				)}

			{pagetype === "complete" &&
				type !== "data" &&
				work?.work_status === "approve" && (
					<>
						<td>
							<div className="row">
								<div className=" danger">APPROVED</div>
							</div>
						</td>
						<td></td>
						<td>{work?.tp_case_worker_remarks}</td>
						<td>{work?.zp_case_worker_remarks}</td>
					</>
				)}

			{pagetype === "complete" &&
				type !== "data" &&
				work?.work_status === "reject" && (
					<>
						<td>
							<div className="row">
								<div className=" danger">REJECTED</div>
							</div>
						</td>
						<td>
							<div className=" danger">{work.work_status_remarks}</div>
						</td>

						<td>{work?.tp_case_worker_remarks}</td>
						<td>{work?.zp_case_worker_remarks}</td>
					</>
				)}

			{(type === "TP-Entry" || type === "ZP-Entry") && (
				<>
					<td>
						<div className="row">
							<div className=" danger">{work?.work_status}</div>
						</div>
					</td>
					<td>
						<div className=" danger">{work.work_status_remarks}</div>
					</td>
				</>
			)}

			{type === "TP-Entry" && pagetype !== "complete" && (
				<>
					<td>
						<textarea
							className="form-control"
							id="workrejectionnotes"
							rows="2"
							onChange={handleTPCaseReport}
						></textarea>
						{!updatedStat ? (
							<button
								type="button"
								className="btn btn-outline-theme mt-2 btn-lg d-block w-100 fw-500 mb-3"
								onClick={handleClick}
							>
								Confirm
							</button>
						) : (
							<div>updated</div>
						)}
					</td>
					<td>{work.zp_case_worker_remarks}</td>
				</>
			)}
			{type === "ZP-Entry" && pagetype !== "complete" && (
				<>
					<td>{work.tp_case_worker_remarks}</td>
					<td>
						<textarea
							className="form-control"
							id="workrejectionnotes"
							rows="2"
							onChange={handleZPCaseReport}
						></textarea>
						{!updatedStat ? (
							<button
								type="button"
								className="btn btn-outline-theme mt-2 btn-lg d-block w-100 fw-500 mb-3"
								onClick={handleClick}
							>
								Confirm
							</button>
						) : (
							<div>updated</div>
						)}
					</td>
				</>
			)}
		</tr>
	);
};
