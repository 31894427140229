import React, { useEffect, useContext, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AppSettings } from "./../../config/app-settings.js";
import { Link, Navigate, useNavigate, Route } from "react-router-dom";
import { Card } from "./../../components/card/card.jsx";
import { getAuth } from "../../config/identity.service";
import {
	getAllPending,
	getAllSubmitted,
	getSubordinates,
	getUserAbstract,
	getUsersAbove,
	getUserAbstractWithIA,
} from "../../config/api.service";
import AsyncSelect from "react-select/async";
import { Button } from "bootstrap";
import { Minus } from "react-feather";
import Loading from "../../components/Loader";

function Abstract() {
	const context = useContext(AppSettings);
	const navigate = useNavigate();

	const [IA, setIA] = useState([
		{ value: null, label: "all" },
		{ value: "GP", label: "GP" },
		{ value: "SF-Forest", label: "SF-Forest" },
		{ value: "TF-Forest", label: "TF-Forest" },
		{ value: "Horticulture", label: "Horticulture" },
		{ value: "Agriculture", label: "Agriculture" },
		{ value: "Sericulture", label: "Sericulture" },
		{ value: "PRED", label: "PRED" },
	]);
	const [FY, setFY] = useState([
		{ value: null, label: "all" },
		{ value: "2023-2024", label: "2023-2024" },
		{ value: "2024-2025", label: "2024-2025" }
	]);
	const [userDesignation, setUserDesignation] = useState();
	const [userIdentity, setUserIdentity] = useState("");
	const [hamburger, setHamburger] = useState(true);
	const [userType, setUserType] = useState("");
	const [subTPdata, setSubTPData] = useState([]);
	const [subGPdata, setSubGPData] = useState([]);
	const [subPDOdata, setSubPDOData] = useState([]);
	const [showFilter, setShowFilter] = useState(false);
	const [abstractData, setAbstractData] = useState([]);
	const [grandTotal, getGrandTotal] = useState({});
	const [toggleAbstract, setToggleAbstract] = useState(false);
	const [taluk, setTaluk] = useState("");
	const [gram, setGram] = useState("");
	const [district, setDistrict] = useState("");
	const [printUserIdentity, setPrintUserIdentity] = useState("");
	const [printUserDesignation, setPrintUserDesignation] = useState("");
	const [IAPrint, setIAPrint] = useState("");
	const [FYPrint, setFYPrint] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleSelectTPChange = async (ev) => {
		const { authJwtToken } = await getAuth();
		const subs = await getSubordinates(
			{
				userId: ev.target.user_id,
				designation: ev.target.value,
			},
			authJwtToken
		);
		const userId = ev.target.user_id;
		const designation = ev.target.value;
		setPrintUserIdentity(userId);
		setPrintUserDesignation(designation);
		if (userId && designation) {
			setLoading(true);
			const serverAbstractData = await getUserAbstract(userId, designation, FYPrint);
			setLoading(false);
			setAbstractData(serverAbstractData.userAbstract);
			getGrandTotal(serverAbstractData.grandTotal);
			const user = await getUsersAbove({ userId, designation });
			if (designation === "ZP-CEO") {
				setDistrict(user?.location);
			} else if (designation === "TP-EO") {
				setTaluk(user?.location);
				setDistrict(user?.zpuser?.location);
			} else if (designation === "GP-President") {
				setGram(user?.location);
				setTaluk(user?.tpuser?.location);
				setDistrict(user?.tpuser?.zpuser?.location);
			} else if (designation === "Entry-User") {
				setGram(user?.gpuser?.location);
				setTaluk(user?.gpuser?.tpuser?.location);
				setDistrict(user?.gpuser?.tpuser?.zpuser?.location);
			}
		}
		let arr = subs?.subordinates.map((el) => {
			return { value: el.designation, label: el.username, user_id: el.id };
		});
		setSubGPData(arr);
	};
	const handleSelectIAChange = async (ev) => {
		const { authJwtToken, user } = await getAuth();
		const userId = user?.id;
		const designation = user?.designation;
		const implementingagency = ev.target.value;
		setPrintUserIdentity(userId);
		setPrintUserDesignation(designation);
		setIAPrint(implementingagency);
		if (userId && designation && implementingagency) {
			setLoading(true);
			const IAabstractData = await getUserAbstract(
				userId,
				designation,
				FYPrint,
				implementingagency
			);
			setLoading(false);
			console.log(IAabstractData);
			setAbstractData(IAabstractData?.userAbstract);
			getGrandTotal(IAabstractData.grandTotal);
		}
	};
	const handleSelectFYChange = async (ev) => {
		const { authJwtToken, user } = await getAuth();
		const userId = user?.id;
		const designation = user?.designation;
		const financialYear = ev.target.value;
		setPrintUserIdentity(userId);
		setPrintUserDesignation(designation);
		setFYPrint(financialYear);
		if (userId && designation && IAPrint) {
			setLoading(true);
			const IAabstractData = await getUserAbstract(
				userId,
				designation,
				financialYear,
				IAPrint
			);
			setLoading(false);
			setAbstractData(IAabstractData?.userAbstract);
			getGrandTotal(IAabstractData.grandTotal);
		} else {
			setLoading(true);
			const IAabstractData = await getUserAbstract(
				userId,
				designation,
				financialYear
			);
			setLoading(false);
			setAbstractData(IAabstractData?.userAbstract);
			getGrandTotal(IAabstractData.grandTotal);
		}
	};
	const handleSelectGPChange = async (ev) => {
		const auth = getAuth();
		const subs = await getSubordinates(
			{
				userId: ev.target.user_id,
				designation: ev.target.value,
			},
			auth?.authJwtToken
		);
		const userId = ev.target.user_id;
		const designation = ev.target.value;
		setPrintUserIdentity(userId);
		setPrintUserDesignation(designation);
		if (userId && designation) {
			setLoading(true);
			const serverAbstractData = await getUserAbstract(userId, designation, FYPrint);
			setLoading(false);
			setAbstractData(serverAbstractData.userAbstract);
			getGrandTotal(serverAbstractData.grandTotal);
			const user = await getUsersAbove({ userId, designation });
			if (designation === "ZP-CEO") {
				setDistrict(user?.location);
			} else if (designation === "TP-EO") {
				setTaluk(user?.location);
				setDistrict(user?.zpuser?.location);
			} else if (designation === "GP-President") {
				setGram(user?.location);
				setTaluk(user?.tpuser?.location);
				setDistrict(user?.tpuser?.zpuser?.location);
			} else if (designation === "Entry-User") {
				setGram(user?.gpuser?.location);
				setTaluk(user?.gpuser?.tpuser?.location);
				setDistrict(user?.gpuser?.tpuser?.zpuser?.location);
			}
		}
		let arr = subs?.subordinates.map((el) => {
			return { value: el.designation, label: el.username, user_id: el.id };
		});
		setSubPDOData(arr);
	};
	const handleSelectPDOChange = async (ev) => {
		const { authJwtToken } = await getAuth();
		const userId = ev.target.user_id;
		const designation = ev.target.value;
		setPrintUserIdentity(userId);
		setPrintUserDesignation(designation);
		if (userId && designation) {
			const serverAbstractData = await getUserAbstract(userId, designation, FYPrint);
			setAbstractData(serverAbstractData.userAbstract);
			getGrandTotal(serverAbstractData.grandTotal);
			const user = await getUsersAbove({ userId, designation });
			if (designation === "ZP-CEO") {
				setDistrict(user?.location);
			} else if (designation === "TP-EO") {
				setTaluk(user?.location);
				setDistrict(user?.zpuser?.location);
			} else if (designation === "GP-President") {
				setGram(user?.location);
				setTaluk(user?.tpuser?.location);
				setDistrict(user?.tpuser?.zpuser?.location);
			} else if (designation === "Entry-User") {
				setGram(user?.gpuser?.location);
				setTaluk(user?.gpuser?.tpuser?.location);
				setDistrict(user?.gpuser?.tpuser?.zpuser?.location);
			}
		}
	};

	useEffect(() => {
		async function getsubs() {
			const auth = getAuth();
			const subs = await getSubordinates(
				{
					userId: auth.user.id,
					designation: auth?.user?.designation,
				},
				auth?.authJwtToken
			);
			let arr = subs?.subordinates.map((el) => {
				return { value: el.designation, label: el.username, user_id: el.id };
			});
			if (auth?.user?.designation === "ZP-CEO") {
				setSubTPData(arr);
			} else if (auth?.user?.designation === "TP-EO") {
				setSubGPData(arr);
			} else if (auth?.user?.designation === "GP-President") {
				setSubPDOData(arr);
			}
		}
		getsubs();
	}, []);

	useEffect(() => {
		async function getpending() {
			const { user, authJwtToken } = await getAuth();

			// const username = user.username;
			const userId = user.id;
			const designation = user.designation;
			setUserIdentity(userId);
			setUserDesignation(designation);
			setPrintUserIdentity(userId);
			setPrintUserDesignation(designation);
			if (userId && designation) {
				setLoading(true);
				const serverAbstractData = await getUserAbstract(userId, designation, FYPrint);
				setLoading(false);
				setAbstractData(serverAbstractData.userAbstract);
				getGrandTotal(serverAbstractData.grandTotal);
				const user = await getUsersAbove({ userId, designation });
				if (designation === "ZP-CEO") {
					setDistrict(user?.location);
				} else if (designation === "TP-EO") {
					setTaluk(user?.location);
					setDistrict(user?.zpuser?.location);
				} else if (designation === "GP-President") {
					setGram(user?.location);
					setTaluk(user?.tpuser?.location);
					setDistrict(user?.tpuser?.zpuser?.location);
				} else if (designation === "Entry-User") {
					setGram(user?.gpuser?.location);
					setTaluk(user?.gpuser?.tpuser?.location);
					setDistrict(user?.gpuser?.tpuser?.zpuser?.location);
				}
			}
		}
		getpending();
	}, []);

	useEffect(() => {
		const auth = getAuth();
		if (auth.user.designation === "Entry-User") {
			setUserType("data");
		}
		if (auth.user.designation === "TP-Entry") {
			setUserType("TP-Entry");
		}
		if (auth.user.designation === "ZP-Entry") {
			setUserType("ZP-Entry");
		}
	}, []);

	const toggleAppSidebarDesktop = () => {
		var elm = document.querySelector(".app");
		setHamburger(!hamburger);
		// elm.classList.toggle('app-sidebar-collapsed');
	};

	const filterTPTypes = (inputValue) => {
		let filterData = subTPdata.filter((c) => {
			return c.label.toLowerCase().includes(inputValue.toLowerCase());
		});

		return filterData;
	};
	const filterGPTypes = (inputValue) => {
		let filterData = subTPdata.filter((c) => {
			return c.label.toLowerCase().includes(inputValue.toLowerCase());
		});

		return filterData;
	};
	const filterPDOTypes = (inputValue) => {
		let filterData = subTPdata.filter((c) => {
			return c.label.toLowerCase().includes(inputValue.toLowerCase());
		});

		return filterData;
	};

	const loadTPOptions = (data, callback) => {
		setTimeout(() => {
			callback(filterTPTypes(data.inputVal));
		}, 1000);
	};
	const loadGPOptions = (data, callback) => {
		setTimeout(() => {
			callback(filterGPTypes(data.inputVal));
		}, 1000);
	};
	const loadPDOOptions = (data, callback) => {
		setTimeout(() => {
			callback(filterPDOTypes(data.inputVal));
		}, 1000);
	};

	const styles = {
		container: (provided, state) => ({
			...provided,
			padding: 2,
			color: state.isFocused ? "blue" : "red",
			zIndex: "999999",
		}),
		control: (base) => ({
			...base,
			border: 0,
			boxShadow: "none",
			zIndex: "999999",
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused ? "#007bff" : null,
			color: state.isFocused ? "white" : null,
			zIndex: "999999",
		}),
	};
	const TablesStles = {
		// height: "200px",
		// width: "682px",
		overflowX: "scroll",
		overflowY: "hidden",
		zIndex: 11000,
	};

	// #container{
	//     width: 3000px;
	// }

	return (
		<Card className="h-100">
			{loading ? (
				<Loading />
			) : (
				<div className="mailbox">
					<div className="mailbox-toolbar flex justify-space-between">
						<button
							onClick={() =>
								toggleAbstract
									? (window.location.href = `${process.env.REACT_APP_PDF_URL
										}/abstractpdf?user_id=${printUserIdentity}&designation=${printUserDesignation}&FY=${FYPrint}${IAPrint ? `&IA=${IAPrint}` : ""
										}`)
									: (window.location.href = `${process.env.REACT_APP_PDF_URL
										}/categoryabstractpdf?user_id=${printUserIdentity}&designation=${printUserDesignation}&FY=${FYPrint}${IAPrint ? `&IA=${IAPrint}` : ""
										}`)
							}
							style={{ height: "40px" }}
							className="ms-auto btn btn-outline-theme btn-lg col-md-2"
						>
							Print Abstract
						</button>
						<button
							onClick={() => setToggleAbstract(!toggleAbstract)}
							style={{ height: "40px" }}
							className="ms-auto btn btn-outline-theme btn-lg col-md-2"
						>
							{toggleAbstract ? "Toggle Category Abstract" : "Toggle Abstract"}
						</button>
						<div className="col-md-2 mx-5">
							<div className="">
								<label className="form-label fs-6">Implementing Agency</label>
							</div>
							<AsyncSelect
								// cacheOptions
								defaultValue={{ label: IAPrint, value: IAPrint }}
								defaultOptions={IA}
								onChange={(val) =>
									handleSelectIAChange({
										target: {
											value: val.value,
											label: val.label,
										},
									})
								}
								className="form-control"
								name="PDO"
								styles={styles}
							/>
						</div>

						<div className="col-md-2 mx-5">
							<div className="">
								<label className="form-label fs-6">Financial Year</label>
							</div>
							<AsyncSelect
								// cacheOptions
								defaultValue={{ label: FYPrint, value: FYPrint }}
								defaultOptions={FY}
								onChange={(val) =>
									handleSelectFYChange({
										target: {
											value: val.value,
											label: val.label,
										},
									})
								}
								className="form-control"
								name="PDO"
								styles={styles}
							/>
						</div>

						{userDesignation !== "Entry-User" && !showFilter ? (
							<button
								onClick={() => setShowFilter(!showFilter)}
								style={{ height: "40px" }}
								className="ms-auto btn btn-outline-theme btn-lg col-md-2"
							>
								Filters
							</button>
						) : (
							<button
								onClick={() => setShowFilter(!showFilter)}
								style={{ height: "40px" }}
								className="ms-auto mr-5 btn col-md-1"
							>
								<Minus />
							</button>
						)}

						{showFilter && (
							<>
								{userDesignation === "ZP-CEO" && (
									<div className="col-md-1 mx-1 mt-4">
										<div className="">
											<label className="form-label">
												TP <span className="text-danger">*</span>
											</label>
										</div>
										<AsyncSelect
											cacheOptions
											// defaultValue={{ label: groupdata.time_zone.value, value: groupdata.time_zone.id }}
											loadOptions={(ev, callback) =>
												loadTPOptions({ inputVal: ev }, callback)
											}
											defaultOptions={subTPdata}
											onChange={(val) =>
												handleSelectTPChange({
													target: {
														user_id: val.user_id,
														value: val.value,
														label: val.label,
													},
												})
											}
											className="form-control"
											name="TP"
											styles={styles}
										/>
									</div>
								)}
								{(userDesignation === "ZP-CEO" ||
									userDesignation === "TP-EO") && (
										<div className="col-md-1 mx-1 mt-4">
											<div className="">
												<label className="form-label">
													GP <span className="text-danger">*</span>
												</label>
											</div>
											<AsyncSelect
												cacheOptions
												// defaultValue={{ label: groupdata.time_zone.value, value: groupdata.time_zone.id }}
												loadOptions={(ev, callback) =>
													loadGPOptions({ inputVal: ev }, callback)
												}
												defaultOptions={subGPdata}
												onChange={(val) =>
													handleSelectGPChange({
														target: {
															user_id: val.user_id,
															value: val.value,
															label: val.label,
														},
													})
												}
												className="form-control"
												name="GP"
												styles={styles}
											/>
										</div>
									)}
								{(userDesignation === "ZP-CEO" ||
									userDesignation === "TP-EO" ||
									userDesignation === "GP-President") && (
										<div className="col-md-1 mx-1 mt-4">
											<div className="">
												<label className="form-label">
													PDO <span className="text-danger">*</span>
												</label>
											</div>
											<AsyncSelect
												cacheOptions
												// defaultValue={{ label: groupdata.time_zone.value, value: groupdata.time_zone.id }}
												loadOptions={(ev, callback) =>
													loadPDOOptions({ inputVal: ev }, callback)
												}
												defaultOptions={subPDOdata}
												onChange={(val) =>
													handleSelectPDOChange({
														target: {
															user_id: val.user_id,
															value: val.value,
															label: val.label,
														},
													})
												}
												className="form-control"
												name="PDO"
												styles={styles}
											/>
										</div>
									)}
							</>
						)}
					</div>
					<div className="mailbox-body" style={{ overflowX: "scroll" }}>
						{toggleAbstract ? (
							<PerfectScrollbar>
								<div id="borderedTable" className="mb-5">
									<table className="table table-bordered mb-0">
										{/* <div className="d-flex justify-content-around">
                                   
                                </div> */}
										<thead>
											<tr>
												<th colspan="3" className="border p-2">
													District:
													<span className="m-2">{district}</span>
												</th>
												<th colspan="3" className="border p-2">
													Taluk:
													<span className="m-2">{taluk}</span>
												</th>
												<th colspan="3" className="border p-2">
													Gram Panchayat:
													<span className="m-2">{gram}</span>
												</th>
											</tr>
											<tr>
												<th scope="col">Serial No.</th>
												<th scope="col">Work Category (WC)</th>
												<th scope="col">No. of Works</th>
												<th scope="col">Estimated Cost (EC) (in lakhs)</th>
												<th scope="col">Wage (W) (in lakhs)</th>
												<th scope="col">Wage % (W%) </th>
												<th scope="col">Material (M) (in lakhs)</th>
												<th scope="col">Material % (M%) </th>
												<th scope="col">Person Days</th>
											</tr>
										</thead>
										<tbody>
											{abstractData?.map((abstract, index) => {
												return (
													<>
														<colgroup>
															<col
																span="2"
																rowspan={
																	abstract?.worktypes?.length > 0
																		? (abstract?.worktypes?.length).toString()
																		: "1"
																}
															/>
															<col rowspan="1" />
														</colgroup>

														<tr>
															<td>{index + 1}</td>
															<td>
																<tr>{abstract.WorkCategory}</tr>
															</td>

															<td>
																<tr>{abstract?.total?.numWorks}</tr>
															</td>
															<td>
																<tr>
																	{abstract?.total?.estimatedCost?.toFixed(5)}
																</tr>
															</td>
															<td>
																<tr>{abstract?.total?.wage?.toFixed(5)}</tr>
															</td>
															<td>
																<tr>{abstract?.total?.wageP?.toFixed(2)}</tr>
															</td>
															<td>
																<tr>
																	{abstract?.total?.materialCost?.toFixed(5)}
																</tr>
															</td>
															<td>
																<tr>
																	{abstract?.total?.materialP?.toFixed(2)}
																</tr>
															</td>
															<td>
																<tr>{abstract?.total?.personDays}</tr>
															</td>
														</tr>
													</>
												);
											})}
											<tr>
												<td>Grand Total: </td>
												<td></td>
												<td>{grandTotal.numWorks}</td>
												<td>{grandTotal.estimatedCost?.toFixed(5)}</td>
												<td>{grandTotal.wage?.toFixed(5)}</td>
												<td>{grandTotal.wageP?.toFixed(2)}</td>
												<td>{grandTotal.materialCost?.toFixed(5)}</td>
												<td>{grandTotal.materialP?.toFixed(2)}</td>
												<td>{grandTotal.personDays}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</PerfectScrollbar>
						) : (
							<div className="mailbox-content" style={{ overflowX: "scroll" }}>
								<PerfectScrollbar>
									<div id="borderedTable" className="mb-5">
										<table className="table table-bordered mb-0">
											<thead>
												<tr>
													<th colspan="3" className="border p-2">
														District:
														<span className="m-2">{district}</span>
													</th>
													<th colspan="3" className="border p-2">
														Taluk:
														<span className="m-2">{taluk}</span>
													</th>
													<th colspan="4" className="border p-2">
														Gram Panchayat:
														<span className="m-2">{gram}</span>
													</th>
												</tr>
												<tr>
													<th scope="col">Serial No.</th>
													<th scope="col">Work Category (WC)</th>
													<th scope="col">Work Type</th>
													<th scope="col">No. of Works</th>
													<th scope="col">Estimated Cost (EC) (in lakhs)</th>
													<th scope="col">Wage (W) (in lakhs)</th>
													<th scope="col">Wage % (W%) </th>
													<th scope="col">Material (M) (in lakhs)</th>
													<th scope="col">Material % (M%) </th>
													<th scope="col">Person Days</th>
												</tr>
											</thead>
											<tbody>
												{abstractData?.map((abstract, index) => {
													return (
														<>
															<colgroup>
																<col
																	span="2"
																	rowspan={
																		abstract?.worktypes?.length > 0
																			? (abstract?.worktypes?.length).toString()
																			: "1"
																	}
																/>
																<col rowspan="1" />
															</colgroup>
															<tr>
																<td>{index + 1}</td>
																<td>{abstract.WorkCategory}</td>
																<td>
																	{abstract?.worktypes?.map((worktype, i) => {
																		return <tr>{worktype?.name}</tr>;
																	})}
																</td>

																<td>
																	{abstract?.worktypes?.map((worktype, i) => {
																		return (
																			<tr>{worktype?.workStats?.numWorks}</tr>
																		);
																	})}
																</td>

																<td>
																	{abstract?.worktypes?.map((worktype, i) => {
																		return (
																			<tr>
																				{worktype?.workStats?.estimatedCost?.toFixed(
																					5
																				)}
																			</tr>
																		);
																	})}
																</td>

																<td>
																	{abstract?.worktypes?.map((worktype, i) => {
																		return (
																			<tr>
																				{worktype?.workStats?.wage?.toFixed(5)}
																			</tr>
																		);
																	})}
																</td>

																<td>
																	{abstract?.worktypes?.map((worktype, i) => {
																		return (
																			<tr>
																				{worktype?.workStats?.wageP?.toFixed(2)}
																			</tr>
																		);
																	})}
																</td>

																<td>
																	{abstract?.worktypes?.map((worktype, i) => {
																		return (
																			<tr>
																				{worktype?.workStats?.materialCost?.toFixed(
																					5
																				)}
																			</tr>
																		);
																	})}
																</td>

																<td>
																	{abstract?.worktypes?.map((worktype, i) => {
																		return (
																			<tr>
																				{worktype?.workStats?.materialP?.toFixed(
																					2
																				)}
																			</tr>
																		);
																	})}
																</td>

																<td>
																	{abstract?.worktypes?.map((worktype, i) => {
																		return (
																			<tr>{worktype?.workStats?.personDays}</tr>
																		);
																	})}
																</td>
															</tr>
															<tr>
																<td>Total</td>
																<td></td>
																<td></td>
																<td>
																	<tr>{abstract?.total?.numWorks}</tr>
																</td>
																<td>
																	<tr>
																		{abstract?.total?.estimatedCost?.toFixed(5)}
																	</tr>
																</td>
																<td>
																	<tr>{abstract?.total?.wage?.toFixed(5)}</tr>
																</td>
																<td>
																	<tr>{abstract?.total?.wageP?.toFixed(2)}</tr>
																</td>
																<td>
																	<tr>
																		{abstract?.total?.materialCost?.toFixed(5)}
																	</tr>
																</td>
																<td>
																	<tr>
																		{abstract?.total?.materialP?.toFixed(2)}
																	</tr>
																</td>
																<td>
																	<tr>{abstract?.total?.personDays}</tr>
																</td>
															</tr>
														</>
													);
												})}
												<tr>
													<td>Grand Total: </td>
													<td></td>
													<td></td>
													<td>{grandTotal.numWorks}</td>
													<td>{grandTotal.estimatedCost?.toFixed(5)}</td>
													<td>{grandTotal.wage?.toFixed(5)}</td>
													<td>{grandTotal.wageP?.toFixed(2)}</td>
													<td>{grandTotal.materialCost?.toFixed(5)}</td>
													<td>{grandTotal.materialP?.toFixed(2)}</td>
													<td>{grandTotal.personDays}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</PerfectScrollbar>
							</div>
						)}
					</div>
				</div>
			)}
		</Card>
	);
}

export default Abstract;
