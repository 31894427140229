import React from "react";
import { deleteWorkType } from "../config/api.service";
import { getAuth } from "../config/identity.service";

export const WorkTypeDeleteList = ({ worktype, index, setworktypes, worktypes }) => {
	const deleteType = async () => {
		const workTypeId = worktype.id;

		const { authJwtToken } = await getAuth();
		const deletedWork = await deleteWorkType({ workTypeId: workTypeId }, authJwtToken);
		if (deletedWork.affected === 1) {
			let temp = worktypes
			const removed = temp.splice(index, 1);
			setworktypes([...temp])
		}

	};

	return (
		<div className="border">
			<span>
				<h4 className="float-left">
					<span className="label label-default">{worktype.name}</span>
				</h4>
				<button
					type="button"
					className="btn btn-outline-theme btn-lg"
					onClick={deleteType}
				>
					Delete Work Type
				</button>
			</span>
		</div>
	);
};
