import React, { useEffect, useState } from "react";
// import jsVectorMap from "jsvectormap";
// import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.min.css";
import {
	Card,
	CardBody,
	// CardExpandToggler,
} from "./../../components/card/card.jsx";
// import Chart from "react-apexcharts";
import { getAuth } from "../../config/identity.service";
import { getDashboardData } from "../../config/api.service.js";
import Loading from "../../components/Loader.jsx";

function Dashboard() {
	const [pendingWorksCount, setPendingWorksCount] = useState();
	const [submittedWorksCount, setSubmittedWorksCount] = useState();
	const [userSubordinate, setUserSubordinates] = useState();
	const [username, setUsername] = useState();
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [loading, setLoading] = useState(false);

	// window.location.reload();

	useEffect(() => {
		const reloadCount = sessionStorage.getItem("reloadCount");
		if (reloadCount < 1) {
			sessionStorage.setItem("reloadCount", String(reloadCount + 1));
			window.location.reload();
		} else {
			sessionStorage.removeItem("reloadCount");
		}
	}, []);
	useEffect(() => {
		async function getDashboarddata() {
			setLoading(true);
			const { user, authJwtToken } = await getAuth();
			const data = await getDashboardData(
				user.id,
				user.designation,
				authJwtToken
			);
			const userId = user.id;
			const designation = user.designation;

			setUsername(user.username);
			setName(user.name);
			setEmail(user.email);

			// const data = await getDashboardData(userId, designation);

			setPendingWorksCount(data.pendingWorks);
			setSubmittedWorksCount(data.submittedWorks);
			setUserSubordinates(data.subordinates);
			setLoading(false);
		}
		getDashboarddata();
	}, []);

	return (
		<div>
			{loading === true ? <Loading /> : null}
			<div className="row">
				<div className="col-xl-4 col-lg-6">
					<Card className="mb-3">
						<CardBody>
							<div className="d-flex fw-bold small mb-3">
								<span className="flex-grow-1">Pending</span>
								{/* <CardExpandToggler /> */}
							</div>
							<div className="row align-items-center mb-2">
								<div className="col-7">
									<h3 className="mb-0">{pendingWorksCount}</h3>
								</div>
								<div className="col-5">
									<div className="mt-n2"></div>
								</div>
							</div>
							<div className="small text-inverse text-opacity-50 text-truncate"></div>
						</CardBody>
					</Card>
				</div>

				<div className="col-xl-4 col-lg-6">
					<Card className="mb-3">
						<CardBody>
							<div className="d-flex fw-bold small mb-3">
								<span className="flex-grow-1">Submitted </span>
								{/* <CardExpandToggler /> */}
							</div>
							<div className="row align-items-center mb-2">
								<div className="col-7">
									<h3 className="mb-0">{submittedWorksCount}</h3>
								</div>
								<div className="col-5">
									<div className="mt-n2"></div>
								</div>
							</div>
							<div className="small text-inverse text-opacity-50 text-truncate"></div>
						</CardBody>
					</Card>
				</div>

				<div className="col-xl-4 col-lg-6">
					<Card className="mb-3">
						<CardBody>
							<div className="d-flex fw-bold small mb-3">
								<span className="flex-grow-1">User Details</span>
								{/* <CardExpandToggler /> */}
							</div>
							<div className="row align-items-center mb-2">
								<div className="col-7">
									<p className="mb-0">{username}</p>
									<p className="mb-0">{name}</p>
									<p className="mb-0">{email}</p>
								</div>
								<div className="col-5">
									<div className="mt-n2"></div>
								</div>
							</div>
							<div className="small text-inverse text-opacity-50 text-truncate"></div>
						</CardBody>
					</Card>
				</div>

				<div className="col-xl-4 col-lg-6">
					<Card className="mb-3">
						<CardBody>
							<div className="d-flex fw-bold small mb-3">
								<span className="flex-grow-1">Subordinates</span>
								{/* <CardExpandToggler /> */}
							</div>
							<div className="row align-items-center mb-2">
								<div className="col-7">
									{/* <h3 className="mb-0">{userSubordinate}</h3> */}
									{userSubordinate?.map((subordinate, i) => {
										return (
											<p key={subordinate.id} className="mb-0">
												{subordinate.username}
											</p>
										);
									})}
								</div>
								<div className="col-5">
									<div className="mt-n2"></div>
								</div>
							</div>
							<div className="small text-inverse text-opacity-50 text-truncate"></div>
						</CardBody>
					</Card>
				</div>
			</div>
		</div>
	);
}

export default Dashboard;
