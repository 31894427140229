import React, { useEffect, useState } from "react";
import {
	createUser,
	getAllGPUsers,
	getAllTPUsers,
	getAllZPUsers,
	getSeedWorkData,
	getWorkTypes,
	postWorkCategory,
	postWorkType,
	getPermissions,
	createOrUpdatePermissions,
} from "../../config/api.service";
import Select from "react-select";
import { WorkTypeDeleteList } from "../../components/WorkTypeDeleteList";
import { isZPAuthenticated } from "../../config/identity.service";
import { getAuth } from "../../config/identity.service";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loader";

function AdminEntryPage() {
	const navigate = useNavigate();
	const [workCategoryName, setWorkCategoryName] = useState("");
	const [workcateogries, setWorkCategories] = useState([]);
	const [worktype, setWorktype] = useState({
		workcategory: "",
		name: "",
		estimated_cost: 0.0,
		wage_cost: 0.0,
		material_cost: 0.0,
		person_days: 0.0,
		isNRM: false,
	});
	const [worktypes, setworktypes] = useState([]);
	const [zpusers, setZpUsers] = useState([]);
	const [tpusers, setTpUsers] = useState([]);
	const [gpusers, setGpUsers] = useState([]);
	const [toggleSwitch, setToggleSwitch] = useState();
	const [permissionSuccess, setPermissionSuccess] = useState(false);
	const [permissionError, setPermissionError] = useState(false);

	const [user, setUser] = useState({
		username: "",
		password: "",
		designation: "",
		location: "",
		name: "",
		mobile: "",
		email: "",
		reportsTo: "",
	});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function protectRoute() {
			const auth = getAuth();
			if (!isZPAuthenticated(auth)) {
				navigate("/pages/login");
			}
		}
		protectRoute();
	}, []);

	useEffect(() => {
		async function getPerms() {
			const auth = getAuth();
			const Perms = await getPermissions({}, auth.authJwtToken);
			if (Perms?.id) {
				setToggleSwitch(Perms?.submit_permissions);
			}
		}
		getPerms();
	}, []);

	useEffect(() => {
		async function fetchWorkLists() {
			try {
				const auth = await getAuth();
				if (auth) {
					const data = await getSeedWorkData(auth?.authJwtToken);
					const workCategories = data.workcategories;
					setWorkCategories(workCategories);
				}
			} catch (e) {
				console.log("error", e);
			}
		}
		fetchWorkLists();
	}, []);

	useEffect(() => {
		async function fetchUsers() {
			const { authJwtToken } = await getAuth();
			const zpusers = await getAllZPUsers(authJwtToken);
			setZpUsers(zpusers);
			const tpusers = await getAllTPUsers(authJwtToken);
			setTpUsers(tpusers);
			const gpusers = await getAllGPUsers(authJwtToken);
			setGpUsers(gpusers);
		}
		fetchUsers();
	}, []);

	const createWorkCategory = async (e) => {
		e.preventDefault();
		setLoading(true);
		const { authJwtToken } = await getAuth();
		await postWorkCategory(workCategoryName, authJwtToken);
		setLoading(false);
		setWorkCategoryName("");
		window.location.reload();
	};

	const createWorkType = async (e) => {
		e.preventDefault();
		setLoading(true);
		const { authJwtToken } = await getAuth();
		await postWorkType(worktype, authJwtToken);
		setLoading(false);
		setWorktype({});
		window.location.reload();
	};

	const handleTextChange = (e) => {
		setWorkCategoryName(e.target.value);
	};

	const onWorkCategoryChange = (option) => {
		setWorktype({ ...worktype, workcategory: option.id });
	};

	const onWorkCategoryDeleteChange = async (option) => {
		const { authJwtToken } = await getAuth();
		const { worktypes } = await getWorkTypes(
			{ workCategoryId: option.id },
			authJwtToken
		);
		setworktypes(worktypes);
	};

	const handleNRMChange = (e) => {
		const isNRM = e.target.checked;
		setWorktype({ ...worktype, isNRM: isNRM });
	};

	const handleWorkTypeChange = (e) => {
		const temp = worktype;
		temp[e.target.name] = e.target.value;
		setWorktype({ ...temp });
	};

	const handleWorkTypeNumberChange = (e) => {
		const temp = worktype;
		temp[e.target.name] = parseFloat(e.target.value);
		setWorktype({ ...temp });
	};

	const handleUserNameChange = (e) => {
		// setUsername(e.target.value);
		setUser({ ...user, username: e.target.value });
	};

	const handlePasswordChange = (e) => {
		// setPassword(e.target.value);
		setUser({ ...user, password: e.target.value });
	};

	const handleNameChange = (e) => {
		// setName(e.target.value);
		setUser({ ...user, name: e.target.value });
	};

	const handleMobileChange = (e) => {
		// setMobile(e.target.value);
		setUser({ ...user, mobile: e.target.value });
	};

	const handleEmailChange = (e) => {
		// setEmail(e.target.value);
		setUser({ ...user, email: e.target.value });
	};

	const handleLocationChange = (e) => {
		setUser({ ...user, location: e.target.value });
	};

	const onReportingUserChange = (option) => {
		setUser({ ...user, reportsTo: option.id });
	};

	const handleCreateZPUserCreate = async (e) => {
		const auth = getAuth();
		e.preventDefault();
		await createUser({ ...user, designation: "ZP-Entry" }, auth?.authJwtToken);
		setLoading(false);
		window.location.reload();
	};

	const handleCreateTPEOCreate = async (e) => {
		const auth = getAuth();
		e.preventDefault();
		setLoading(true);
		await createUser({ ...user, designation: "TP-EO" }, auth?.authJwtToken);
		setLoading(false);
		window.location.reload();
	};

	const handleCreateTPEntryWorker = async (e) => {
		const auth = getAuth();
		e.preventDefault();
		// setLoading(true);
		await createUser({ ...user, designation: "TP-Entry" }, auth?.authJwtToken);
		// setLoading(false);
		window.location.reload();
	};

	const handleCreateGPUser = async (e) => {
		const auth = getAuth();
		e.preventDefault();
		setLoading(true);
		await createUser(
			{ ...user, designation: "GP-President" },
			auth?.authJwtToken
		);
		setLoading(false);
		window.location.reload();
	};

	const handleCreatePDOUser = async (e) => {
		const auth = getAuth();
		e.preventDefault();
		setLoading(true);
		await createUser(
			{ ...user, designation: "Entry-User" },
			auth?.authJwtToken
		);
		setLoading(false);
		window.location.reload();
	};

	const OnSwitchChange = async (e) => {
		const auth = getAuth();
		setToggleSwitch(e.target.checked);
		const updated = await createOrUpdatePermissions(
			{ permission: e.target.checked },
			auth?.authJwtToken
		);
		if (updated?.affected === 1) {
			setPermissionError(false);
			setPermissionSuccess(true);
		} else {
			setPermissionSuccess(false);
			setPermissionError(true);
		}
	};

	const styles = {
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused ? "#007bff" : null,
			color: state.isFocused ? "white" : "black",
			zIndex: "999999",
		}),
	};

	return (
		<>
			<h3>Works :</h3>
			<div className="mb-1 p-3 border d-flex justify-content-center">
				{permissionSuccess && (
					<div className="alert alert-success" role="alert">
						{toggleSwitch
							? "Reinstated Submit Permissions!"
							: "Revoked Submit Permissions"}
					</div>
				)}

				{permissionError && (
					<div className="alert alert-danger" role="alert">
						ERROR: Could'nt set Submit Permissions
					</div>
				)}

				<div className="form-check form-switch">
					<label className="form-check-label" for="workSubmissionSwitch">
						Toggle WorkList Submission
					</label>
					<input
						className="form-check-input"
						type="checkbox"
						role="switch"
						id="workSubmissionSwitch"
						onClick={OnSwitchChange}
						defaultChecked={toggleSwitch}
					/>
				</div>
			</div>
			<div className="mb-1 p-3 border">
				{loading === true ? (
					<div
						class=""
						style={{
							display: "block",
							position: "fixed",
							margin: "auto",
							top: "0",
							left: "0",
							bottom: "0",
							right: "0",
							height: "100%",
							width: "100%",
							zIndex: "9999",
							backgroundColor: "rgba(255,255,255,0.5)",
						}}
					>
						<div class="spinner-border" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
				) : null}
				<div>
					<h5>CREATE WORK CATEGORY :</h5>
				</div>
				<div className="d-flex justify-content-center">
					<form onSubmit={createWorkCategory}>
						<div className="mb-3">
							<label htmlFor="inputEmail3" className=" col-form-label">
								Work Category Name:
							</label>
							<div className="">
								<input
									type="text"
									className="form-control"
									value={workCategoryName}
									name="name"
									onChange={handleTextChange}
									required
								/>
							</div>
						</div>
						<button
							type="submit"
							className="btn btn-outline-theme btn-lg"
							// onClick={createWorkCategory}
						>
							Submit Work Category
						</button>
					</form>
				</div>
			</div>
			<div className="mb-1 p-3 border">
				<h5>CREATE WORK TYPE :</h5>
				<div className="d-flex justify-content-center mb-3">
					<Select
						className="col-sm-4"
						styles={styles}
						options={workcateogries}
						getOptionLabel={(option) => option.name}
						getOptionValue={(option) => option.id}
						onChange={(option) => onWorkCategoryChange(option)}
					/>
				</div>

				{worktype.workcategory ? (
					<form onSubmit={createWorkType}>
						<div>
							<div className="mb-3 row">
								<label
									htmlFor="inputEmail3"
									className="col-sm-2 col-form-label"
								>
									Work Type Name:
								</label>
								<div className="col-sm-3">
									<input
										type="text"
										className="form-control"
										// value={workCategoryName}
										name="name"
										onChange={handleWorkTypeChange}
										required
									/>
								</div>
							</div>
							<div className="mb-3 row">
								<label
									htmlFor="inputEmail3"
									className="col-sm-2 col-form-label"
								>
									Estimated Cost (in lakhs):
								</label>
								<div className="col-sm-3">
									<input
										type="number"
										className="form-control"
										// value={workCategoryName}
										name="estimated_cost"
										onChange={handleWorkTypeNumberChange}
										required
										step="0.00001"
									/>
								</div>
							</div>
							<div className="mb-3 row">
								<label
									htmlFor="inputEmail3"
									className="col-sm-2 col-form-label"
								>
									Wage Cost (in lakhs):
								</label>
								<div className="col-sm-3">
									<input
										type="number"
										className="form-control"
										// value={workCategoryName}
										name="wage_cost"
										onChange={handleWorkTypeNumberChange}
										required
										step="0.00001"
									/>
								</div>
							</div>
							<div className="mb-3 row">
								<label
									htmlFor="inputEmail3"
									className="col-sm-2 col-form-label"
								>
									Material Cost (in lakhs):
								</label>
								<div className="col-sm-3">
									<input
										type="number"
										className="form-control"
										// value={workCategoryName}
										name="material_cost"
										onChange={handleWorkTypeNumberChange}
										required
										step="0.00001"
									/>
								</div>
							</div>
							<div className="mb-3 row">
								<label
									htmlFor="inputEmail3"
									className="col-sm-2 col-form-label"
								>
									Person Days:
								</label>
								<div className="col-sm-3">
									<input
										type="number"
										className="form-control"
										// value={workCategoryName}
										name="person_days"
										onChange={handleWorkTypeNumberChange}
										required
										step="0.00001"
									/>
								</div>
							</div>
							<div className="mb-3 row">
								<span className="">
									<label
										className="form-check-label mr-5"
										htmlFor="defaultCheck1"
									>
										isNRM:
									</label>
									&nbsp; &nbsp;
									<input
										className=""
										type="checkbox"
										value={worktype.isNRM}
										onChange={handleNRMChange}
										name="isNRM"
										// required
									/>
								</span>
							</div>
							<button
								type="submit"
								className="btn btn-outline-theme btn-lg"
								// onClick={createWorkType}
							>
								Submit Work Type
							</button>
						</div>
					</form>
				) : null}
			</div>

			<div className="mb-1 p-3 border">
				<h5>DELETE WORK TYPE :</h5>
				<div className="mb-3 row d-flex justify-content-center">
					<Select
						className="col-sm-4"
						styles={styles}
						options={workcateogries}
						getOptionLabel={(option) => option.name}
						getOptionValue={(option) => option.id}
						onChange={(option) => onWorkCategoryDeleteChange(option)}
					/>
					{worktypes?.map((worktype, index) => {
						return (
							<WorkTypeDeleteList
								key={worktype.id}
								index={index}
								worktype={worktype}
								setworktypes={setworktypes}
								worktypes={worktypes}
							/>
						);
					})}
				</div>
			</div>

			<h3>Users :</h3>
			<div className="mb-1 p-3 border">
				<h5>CREATE ZP CEO ENTRY WORKER</h5>
				<form onSubmit={handleCreateZPUserCreate}>
					<div className="mb-3 row">
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Username:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className=" form-control "
									// value={user.username}
									name="name"
									onChange={handleUserNameChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Password:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.password}
									name="name"
									onChange={handlePasswordChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Name:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.name}
									name="name"
									onChange={handleNameChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Mobile:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.mobile}
									name="name"
									onChange={handleMobileChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Email:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.email}
									name="name"
									onChange={handleEmailChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<div>
								<label
									htmlFor="inputEmail3"
									className="col-sm-2 col-form-label"
								>
									<strong>ZP CEO ACCOUNT:</strong>
								</label>
							</div>
							<Select
								styles={styles}
								className="col-md-4"
								options={zpusers}
								getOptionLabel={(option) => option.username}
								getOptionValue={(option) => option.id}
								onChange={(option) => onReportingUserChange(option)}
								required
							/>
						</div>
						<div className="d-flex justify-content-center">
							<button
								type="submit"
								className=" col-sm-3 btn btn-outline-theme btn-lg"
							>
								Create User
							</button>
						</div>
					</div>
				</form>
			</div>

			<div className=" mb-1 p-3 border">
				<h5>CREATE TP EO ACCOUNT</h5>
				<form onSubmit={handleCreateTPEOCreate}>
					<div className="mb-3 row">
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Username:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.username}
									name="name"
									onChange={handleUserNameChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Password:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.password}
									name="name"
									onChange={handlePasswordChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Name:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.name}
									name="name"
									onChange={handleNameChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Mobile:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.mobile}
									name="name"
									onChange={handleMobileChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Email:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.email}
									name="name"
									onChange={handleEmailChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Taluk Name:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.location}
									name="name"
									onChange={handleLocationChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<div>
								<label
									htmlFor="inputEmail3"
									className="col-sm-2 col-form-label"
								>
									<strong>ZP CEO Account:</strong>
								</label>
							</div>
							<Select
								styles={styles}
								className="col-md-4"
								options={zpusers}
								getOptionLabel={(option) => option.username}
								getOptionValue={(option) => option.id}
								onChange={(option) => onReportingUserChange(option)}
								required
							/>
						</div>
						<div className="d-flex justify-content-center">
							<button
								type="submit"
								className=" col-sm-3 btn btn-outline-theme btn-lg"
							>
								Create User
							</button>
						</div>
					</div>
				</form>
			</div>

			<div className=" mb-1 p-3 border">
				<h5>CREATE TP EO ENTRY WORKER</h5>
				<form onSubmit={handleCreateTPEntryWorker}>
					<div className="mb-3 row">
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Username:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.username}
									name="name"
									onChange={handleUserNameChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Password:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.password}
									name="name"
									onChange={handlePasswordChange}
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Name:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.name}
									name="name"
									onChange={handleNameChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Mobile:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.mobile}
									name="name"
									onChange={handleMobileChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Email:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.email}
									name="name"
									onChange={handleEmailChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<div>
								<label
									htmlFor="inputEmail3"
									className="col-sm-2 col-form-label"
								>
									<strong>TP EO Account:</strong>
								</label>
							</div>
							<Select
								styles={styles}
								className="col-md-4"
								options={tpusers}
								getOptionLabel={(option) => option.username}
								getOptionValue={(option) => option.id}
								onChange={(option) => onReportingUserChange(option)}
								required
							/>
						</div>
						<div className="d-flex justify-content-center">
							<button
								type="submit"
								className=" col-sm-3 btn btn-outline-theme btn-lg"
							>
								Create User
							</button>
						</div>
					</div>
				</form>
			</div>

			<div className=" mb-1 p-3 border">
				<h5>CREATE GP PRESIDENT ACCOUNT</h5>
				<form onSubmit={handleCreateGPUser}>
					<div className="mb-3 row">
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Username:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.username}
									name="name"
									onChange={handleUserNameChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Password:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.password}
									name="name"
									onChange={handlePasswordChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Name:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.name}
									name="name"
									onChange={handleNameChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Mobile:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.mobile}
									name="name"
									onChange={handleMobileChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Email:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.email}
									name="name"
									onChange={handleEmailChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Grama Panchayat Name:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.location}
									name="name"
									onChange={handleLocationChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<div>
								<label
									htmlFor="inputEmail3"
									className="col-sm-2 col-form-label"
								>
									<strong>TP EO Account:</strong>
								</label>
							</div>
							<Select
								styles={styles}
								className="col-md-4"
								options={tpusers}
								getOptionLabel={(option) => option.username}
								getOptionValue={(option) => option.id}
								onChange={(option) => onReportingUserChange(option)}
								required
							/>
						</div>
						<div className="d-flex justify-content-center">
							<button
								type="submit"
								className=" col-sm-3 btn btn-outline-theme btn-lg"
							>
								Create User
							</button>
						</div>
					</div>
				</form>
			</div>

			<div className=" mb-1 p-3 border">
				<h5>CREATE GP PDO ACCOUNT</h5>
				<form onSubmit={handleCreatePDOUser}>
					<div className="mb-3 row">
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Username:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.username}
									name="name"
									onChange={handleUserNameChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Password:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.password}
									name="name"
									onChange={handlePasswordChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Name:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.name}
									name="name"
									onChange={handleNameChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Mobile:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.mobile}
									name="name"
									onChange={handleMobileChange}
									required
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Email:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.email}
									name="name"
									onChange={handleEmailChange}
									required
								/>
							</div>
						</div>
						{/* <div className="mb-3 row">
							<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
								<strong>Grama Panchayat Name:</strong>
							</label>
							<div className="d-flex justify-content-center col-md-3">
								<input
									type="text"
									className="form-control"
									// value={user.location}
									name="name"
									onChange={handleLocationChange}
									required
								/>
							</div>
						</div> */}
						<div className="mb-3 row">
							<div>
								<label
									htmlFor="inputEmail3"
									className="col-sm-2 col-form-label"
								>
									<strong>GP President Account:</strong>
								</label>
							</div>
							<Select
								styles={styles}
								className="col-md-4"
								options={gpusers}
								getOptionLabel={(option) => option.username}
								getOptionValue={(option) => option.id}
								onChange={(option) => onReportingUserChange(option)}
								required
							/>
						</div>
						<div className="d-flex justify-content-center">
							<button
								type="submit"
								className=" col-sm-3 btn btn-outline-theme btn-lg"
							>
								Create User
							</button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default AdminEntryPage;
