import React, { useEffect, useContext, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AppSettings } from "./../../config/app-settings.js";
import { Link, Navigate, useNavigate, Route } from "react-router-dom";
import { Card } from "./../../components/card/card.jsx";
import { getAuth } from "../../config/identity.service";
import {
	getAllPending,
	getAllSubmitted,
	getSubordinates,
} from "../../config/api.service";
import { getWorkListsData } from "../../config/api.service";
import { Table } from "../../components/Table.jsx";
import AsyncSelect from "react-select/async";
import { Minus } from "react-feather";
import Loading from "../../components/Loader.jsx";

function Complete() {
	const context = useContext(AppSettings);
	const navigate = useNavigate();
	const [mailData, setMailData] = useState();
	const [pendingWorkList, setPendingWorkList] = useState([]);
	const [FYOptions, setFYOptions] = useState([{ label: 'all', value: null }, { label: '2023-2024', value: '2023-2024' }, { label: '2024-2025', value: '2024-2025' }]);
	const [FY, setFY] = useState(null);
	const [filterUserId, setFilterUserId] = useState();
	const [filterDesignation, setFilterDesignation] = useState();
	const [userDesignation, setUserDesignation] = useState();
	const [senderDesignation, setSenderDesignation] = useState();
	const [worklistDetails, setWorklistDetails] = useState();
	const [hamburger, setHamburger] = useState(true);
	const [userType, setUserType] = useState("");
	const [subTPdata, setSubTPData] = useState([]);
	const [subGPdata, setSubGPData] = useState([]);
	const [subPDOdata, setSubPDOData] = useState([]);
	const [showFilter, setShowFilter] = useState(false);
	const [authToken, setAuthToken] = useState();
	const [loading, setLoading] = useState(false);

	const handleSelectTPChange = async (ev) => {
		const { authJwtToken } = await getAuth();
		setFilterUserId(ev.target.user_id)
		setFilterDesignation(ev.target.value)
		const subs = await getSubordinates(
			{
				userId: ev.target.user_id,
				designation: ev.target.value,
			},
			authToken
		);
		const pendingWorkLists = await getAllSubmitted(
			ev.target.user_id,
			ev.target.value,
			authJwtToken,
			FY
		);
		setPendingWorkList(pendingWorkLists?.worklists);
		let arr = subs?.subordinates?.map((el) => {
			return { value: el.designation, label: el.username, user_id: el.id };
		});
		setSubGPData(arr);
	};

	const handleSelectFYChange = async (ev) => {
		const { authJwtToken, user } = await getAuth();
		const userId = user?.id;
		const designation = user?.designation;
		const financialYear = ev.target.value;
		setFY(financialYear)
		const pendingWorkLists = await getAllSubmitted(
			filterUserId,
			filterDesignation,
			authJwtToken,
			financialYear
		);
		setPendingWorkList(pendingWorkLists?.worklists);
	};


	const handleSelectGPChange = async (ev) => {
		const auth = getAuth();
		setFilterUserId(ev.target.user_id)
		setFilterDesignation(ev.target.value)
		const subs = await getSubordinates(
			{
				userId: ev.target.user_id,
				designation: ev.target.value,
			},
			auth?.authJwtToken
		);
		const pendingWorkLists = await getAllSubmitted(
			ev.target.user_id,
			ev.target.value,
			auth?.authJwtToken,
			FY
		);
		setPendingWorkList(pendingWorkLists?.worklists);
		let arr = subs?.subordinates?.map((el) => {
			return { value: el.designation, label: el.username, user_id: el.id };
		});
		setSubPDOData(arr);
	};
	const handleSelectPDOChange = async (ev) => {
		const { authJwtToken } = await getAuth();
		setFilterUserId(ev.target.user_id)
		setFilterDesignation(ev.target.value)
		const pendingWorkLists = await getAllSubmitted(
			ev.target.user_id,
			ev.target.value,
			authJwtToken,
			FY
		);
		setPendingWorkList(pendingWorkLists?.worklists);
	};

	useEffect(() => {
		context.setAppContentFullHeight(true);
		context.setAppContentClass("p-3");

		fetch("/assets/data/email/inbox.json")
			.then((res) => res.json())
			.then((result) => {
				setMailData(result);
			});

		return function cleanUp() {
			context.setAppContentFullHeight(false);
			context.setAppContentClass("");
		};

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		async function getsubs() {
			const auth = getAuth();
			const subs = await getSubordinates(
				{
					userId: auth.user.id,
					designation: auth?.user?.designation,
				},
				auth?.authJwtToken
			);
			setAuthToken(auth?.authJwtToken);
			let arr = subs?.subordinates?.map((el) => {
				return { value: el.designation, label: el.username, user_id: el.id };
			});
			if (auth?.user?.designation === "ZP-CEO") {
				setSubTPData(arr);
			} else if (auth?.user?.designation === "TP-EO") {
				setSubGPData(arr);
			} else if (auth?.user?.designation === "GP-President") {
				setSubPDOData(arr);
			}
		}
		getsubs();
	}, []);

	useEffect(() => {
		async function getpending() {
			const { user, authJwtToken } = await getAuth();

			// const username = user.username;
			const userId = user.id;
			const designation = user.designation;
			setFilterUserId(userId)
			setFilterDesignation(designation)
			function senderdesignation(accountDesignation) {
				if (accountDesignation === "ZP-CEO") {
					return "tpuser";
				} else if (accountDesignation === "TP-EO") {
					return "gpuser";
				} else if (accountDesignation === "GP-President") {
					return "entryuser";
				} else if (accountDesignation === "Entry-User") {
					return "entryuser";
				} else {
					return "unknown";
				}
			}
			const sendUserDesignation = senderdesignation(designation);
			setUserDesignation(designation);
			setSenderDesignation(sendUserDesignation);
			setLoading(true);
			const pendingWorkLists = await getAllSubmitted(
				userId,
				designation,
				authJwtToken,
				FY
			);
			console.log(pendingWorkLists)
			setLoading(false);
			setPendingWorkList(pendingWorkLists?.worklists);
		}
		getpending();
	}, []);

	useEffect(() => {
		const auth = getAuth();
		if (auth.user.designation === "Entry-User") {
			setUserType("data");
		}
		if (auth.user.designation === "TP-Entry") {
			setUserType("TP-Entry");
		}
		if (auth.user.designation === "ZP-Entry") {
			setUserType("ZP-Entry");
		}
		if (auth.user.designation === "ZP-CEO") {
			setUserType("ZP-CEO");
		}
	}, []);

	const handleWorkListClick = async (worklistId) => {
		try {
			const auth = await getAuth();
			if (auth) {
				const workListDetails = await getWorkListsData(
					worklistId,
					auth?.authJwtToken
				);
				setWorklistDetails(workListDetails);
				setHamburger(false);
			}
		} catch (e) {
			console.log("error", e);
		}
	};

	const toggleAppSidebarDesktop = () => {
		var elm = document.querySelector(".app");
		setHamburger(!hamburger);
		// elm.classList.toggle('app-sidebar-collapsed');
	};

	const filterTPTypes = (inputValue) => {
		let filterData = subTPdata.filter((c) => {
			return c.label.toLowerCase().includes(inputValue.toLowerCase());
		});

		return filterData;
	};
	const filterGPTypes = (inputValue) => {
		let filterData = subGPdata.filter((c) => {
			return c.label.toLowerCase().includes(inputValue.toLowerCase());
		});

		return filterData;
	};
	const filterPDOTypes = (inputValue) => {
		let filterData = subPDOdata.filter((c) => {
			return c.label.toLowerCase().includes(inputValue.toLowerCase());
		});

		return filterData;
	};

	const loadTPOptions = (data, callback) => {
		setTimeout(() => {
			callback(filterTPTypes(data.inputVal));
		}, 1000);
	};
	const loadGPOptions = (data, callback) => {
		setTimeout(() => {
			callback(filterGPTypes(data.inputVal));
		}, 1000);
	};
	const loadPDOOptions = (data, callback) => {
		setTimeout(() => {
			callback(filterPDOTypes(data.inputVal));
		}, 1000);
	};

	const styles = {
		container: (provided, state) => ({
			...provided,
			padding: 2,
			color: state.isFocused ? "blue" : "red",

			zIndex: "999999",
		}),
		control: (base, state) => ({
			...base,
			border: 0,
			boxShadow: "none",
			zIndex: "999999",
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused ? "#007bff" : null,
			color: state.isFocused ? "white" : null,
			zIndex: "999999",
		}),
	};

	return (
		<Card className="h-100">
			<div className="mailbox">
				{loading === true ? <Loading /> : null}
				<div className="mailbox-toolbar flex justify-space-between">
					<div className="desktop-toggler">
						{/* <button type="button" className="menu-toggler" onClick={toggleAppSidebarDesktop}>
							<span >+</span>
							<span className="bar"></span>
							<span className="bar"></span>
						</button> */}
						{!hamburger && (
							<h1 role="button" onClick={toggleAppSidebarDesktop}>
								+
							</h1>
						)}
						{hamburger && (
							<h1 role="button" onClick={toggleAppSidebarDesktop}>
								-
							</h1>
						)}
					</div>
					{userDesignation === "Entry-User" &&
						<div className="col-md-2 mx-5">
							<div className="">
								<label className="form-label fs-6">Financial Year</label>
							</div>
							<AsyncSelect
								// cacheOptions
								defaultValue={FYOptions[0]}
								defaultOptions={FYOptions}
								onChange={(val) =>
									handleSelectFYChange({
										target: {
											value: val.value,
											label: val.label,
										},
									})
								}
								className="form-control"
								name="PDO"
								styles={styles}
							/>
						</div>
					}
					{userDesignation !== "Entry-User" && !showFilter ? (
						<button
							onClick={() => setShowFilter(!showFilter)}
							style={{ height: "40px" }}
							className="ms-auto btn btn-outline-theme btn-lg col-md-2"
						>
							Filters
						</button>
					) : (
						<button
							onClick={() => setShowFilter(!showFilter)}
							style={{ height: "40px" }}
							className="ms-auto mr-5 btn col-md-1"
						>
							<Minus />
						</button>
					)}

					{showFilter && (
						<>
							<div className="col-md-2 mx-5">
								<div className="">
									<label className="form-label fs-6">Financial Year</label>
								</div>
								<AsyncSelect
									// cacheOptions
									defaultValue={FYOptions[0]}
									defaultOptions={FYOptions}
									onChange={(val) =>
										handleSelectFYChange({
											target: {
												value: val.value,
												label: val.label,
											},
										})
									}
									className="form-control"
									name="PDO"
									styles={styles}
								/>
							</div>
							{userDesignation === "ZP-CEO" && (
								<div className="col-md-2 ">
									<div className="">
										<label className="form-label">
											TP <span className="text-danger">*</span>
										</label>
									</div>
									<AsyncSelect
										cacheOptions
										loadOptions={(ev, callback) =>
											loadTPOptions({ inputVal: ev }, callback)
										}
										defaultOptions={subTPdata}
										onChange={(val) =>
											handleSelectTPChange({
												target: {
													user_id: val.user_id,
													value: val.value,
													label: val.label,
												},
											})
										}
										className="form-control"
										name="TP"
										styles={styles}
									/>
								</div>
							)}
							{(userDesignation === "ZP-CEO" ||
								userDesignation === "TP-EO") && (
									<div className="col-md-2 ">
										<div className="">
											<label className="form-label">
												GP <span className="text-danger">*</span>
											</label>
										</div>
										<AsyncSelect
											cacheOptions
											// defaultValue={{ label: groupdata.time_zone.value, value: groupdata.time_zone.id }}
											loadOptions={(ev, callback) =>
												loadGPOptions({ inputVal: ev }, callback)
											}
											defaultOptions={subGPdata}
											onChange={(val) =>
												handleSelectGPChange({
													target: {
														user_id: val.user_id,
														value: val.value,
														label: val.label,
													},
												})
											}
											// className="form-control"
											name="GP"
											styles={styles}
										/>
									</div>
								)}
							{(userDesignation === "ZP-CEO" ||
								userDesignation === "TP-EO" ||
								userDesignation === "GP-President") && (
									<div className="col-md-2 ">
										<div className="">
											<label className="form-label">
												PDO <span className="text-danger">*</span>
											</label>
										</div>
										<AsyncSelect
											cacheOptions
											// defaultValue={{ label: groupdata.time_zone.value, value: groupdata.time_zone.id }}
											loadOptions={(ev, callback) =>
												loadPDOOptions({ inputVal: ev }, callback)
											}
											defaultOptions={subPDOdata}
											onChange={(val) =>
												handleSelectPDOChange({
													target: {
														user_id: val.user_id,
														value: val.value,
														label: val.label,
													},
												})
											}
											className="form-control"
											name="PDO"
											styles={styles}
										/>
									</div>
								)}
						</>
					)}
				</div>
				<div className="mailbox-body" style={{ overflowX: "scroll" }}>
					{hamburger && (
						<div className="mailbox-sidebar d-none d-lg-block">
							<PerfectScrollbar className="h-100">
								<div className="mailbox-list">
									{pendingWorkList && pendingWorkList.length > 0 ? (
										pendingWorkList?.map((el, index) => (
											<div
												key={index}
												className={
													"mailbox-list-item" +
													(el.unread ? " unread" : "") +
													(el.hasAttachment ? " has-attachment" : "")
												}
												onClick={() => handleWorkListClick(el.id)}
											>
												<div className="mailbox-message">
													<Link
														to="/email/complete"
														className="mailbox-list-item-link"
													>
														<div className="mailbox-sender">
															<span className="mailbox-sender-name">
																{el[senderDesignation]?.username}
															</span>

															<span className="mailbox-time">
																Submitted At:{""}
																{new Date(
																	el[`${senderDesignation}_submitted_at`]
																).toLocaleDateString()}
															</span>
														</div>
														{(userType === 'ZP-CEO' || userType === 'ZP-Entry') &&
															<div className="mailbox-sender-name">
																{el["gpuser"]?.username}
															</div>
														}

													</Link>
												</div>
											</div>
										))
									) : (
										<div
											className="mailbox-list-item"
											style={{ minWidth: "360px" }}
										>
											No records
										</div>
									)}
								</div>
							</PerfectScrollbar>
						</div>
					)}
					<div className="mailbox-content">
						<PerfectScrollbar className="h-100">
							{worklistDetails ? (
								<>
									<Table
										workListDetails={worklistDetails}
										type={userType}
										pagetype={"complete"}
									/>
								</>
							) : null}
						</PerfectScrollbar>
					</div>
				</div>
				{/* {userType === "data" && worklistDetails && (
					<button
						onClick={navtocreate}
						className=" btn btn-outline-theme btn-lg mb-3"
					>
						Edit Worklist
					</button>
				)}
				{!userType === "data" && worklistDetails && (
					<button
						onClick={navtocreate}
						className=" btn btn-outline-theme btn-lg mb-3"
					>
						confirm and submit Worklist
					</button>
				)} */}
			</div>
		</Card>
	);
}

export default Complete;
