import Cookies from "universal-cookie";

const cookies = new Cookies();

export const getAuth = () => cookies.get("AUTH");

export const setAuth = (authObject) => {
	cookies.set("AUTH", JSON.stringify(authObject), { path: "/" });
	return authObject;
};

export const isAuthenticated = (user) =>
	user != null && user.authJwtToken && user.user.id !== null;
export const isZPAuthenticated = (user) =>
	user != null && user.authJwtToken && user.user.designation === "ZP-CEO";
export const isGPOAuthenticated = (user) =>
	user != null && user.authJwtToken && user.user.designation === "Entry-User";

export const isProtected = (route) => {
	const unprotectedRoutes = [
		"/pages/login",
		"/forgotpassword",
		"/resetpassword",
	];
	return !unprotectedRoutes.includes(route);
};

export const isBrowser = () => typeof window !== "undefined";

export const WithAuth = ({ router, setIfNotAuth, children }) => {
	const auth = getAuth();
	if (isBrowser() && !isAuthenticated(auth) && isProtected(router.pathname)) {
		setIfNotAuth(true);
	}
	return children;
};

export const removeAuth = () => {
	cookies.remove("AUTH", { path: "/" });
};
