import React, { useEffect, useContext } from 'react';
import Pdfview from '../../../components/pdf/pdfview'
// import { Card } from './../../components/card/card.jsx';


function Pdf() {


	return (
		// <Card className="h-100">
		<Pdfview></Pdfview>
		// </Card>
	)
}

export default Pdf;