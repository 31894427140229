import React, { useEffect, useState } from "react";
import { Card, CardBody } from "./card/card";
import Highlight from "react-highlight";
import Moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TableRow } from "./TableRow";
import { getAuth } from "../config/identity.service";
import { Printer, Download } from "react-feather";
import { Link, Navigate, useNavigate, Route } from "react-router-dom";
import fileDownload from "js-file-download";
import axios from "axios";
var AWS = require("aws-sdk");

var credentials = {
	accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
	secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
	region: "ap-south-1",
};
AWS.config.update({ credentials: credentials, region: "ap-south-1" });
var s3 = new AWS.S3({ signatureVersion: "v4" });

export const Table = ({
	workListDetails,
	type,
	setWorkListPart2,
	workListPart2,
	page,
	pagetype,
	setUserReport,
	userReport,
	setGsproceeding,
	setGsphoto,
	setRemarks,
	remarks,
}) => {
	const [workStatus, setWorkStatus] = useState("approve");
	const [designation, setDesignation] = useState("");
	// const [startDate, setStartDate] = useState(new Date());
	const [startDate, setStartDate] = useState();

	const handleDownload = async (uri) => {
		const url = new URL(uri);
		const key = decodeURIComponent(url.pathname.slice(1));
		console.log(key);
		const presignedurl = s3.getSignedUrl("getObject", {
			Bucket: "udupi-mgnrega-worklist-data",
			Key: key, //filename
			Expires: 100, //time to expire in seconds
		});
		console.log(presignedurl);
		await axios
			.get(presignedurl, {
				responseType: "blob",
			})
			.then((res) => {
				fileDownload(res.data, key);
			});
	};

	function handleChange(date) {
		setStartDate(date);
	}
	const navigate = useNavigate();

	useEffect(() => {
		const auth = getAuth();
		const userDesg = auth?.user?.designation;
		setDesignation(userDesg);
	}, []);
	const works = workListDetails?.works;

	const handleSelectChange = (ev) => {
		let tempData = workListPart2;
		tempData[ev.target.name] = ev.target.value;
		setWorkListPart2(tempData);
	};

	const handleFileChange = (ev) => {
		if (ev.target.name === "GS_Photo") {
			setGsphoto(ev.target.files[0]);
		}
		if (ev.target.name === "GS_Proceeding") {
			setGsproceeding(ev.target.files[0]);
		}
		// mediaForUpload = prescription[0]
	};

	const handleTimeChange = (ev, date) => {
		try {
			let tempData = workListPart2;
			setStartDate(ev.target.value);
			const standardStartDate = new Date(ev.target.value).toISOString();
			tempData["GS_Date"] = standardStartDate;
			setWorkListPart2(tempData);
		} catch (e) {
			console.log(e);
		}
	};

	const handleReportChange = (ev) => {
		setUserReport(ev.target.value);
	};

	return (
		<div id="borderedTable" className="">
			<Card>
				<CardBody>
					<div className="row">
						<h3 className="col-4">
							District: {workListDetails?.zpuser?.location}
						</h3>
						<h3 className="col-4">
							Taluk: {workListDetails?.tpuser?.location}
						</h3>
						<h3 className="col-4">
							Grama Panchayat: {workListDetails?.gpuser?.location}
						</h3>
					</div>
					<table className="table table-bordered" style={{ overflowX: "auto" }}>
						<thead>
							<tr>
								<th scope="col">Serial No.</th>
								<th scope="col">FY</th>
								<th scope="col">Work Category (WC)</th>
								<th scope="col">Work Type (WT)</th>
								<th scope="col">Work Name (WN)</th>
								<th scope="col">Estimated Cost (EC) (in lakhs)</th>
								<th scope="col">Wage (W) (in lakhs)</th>
								<th scope="col">Material (M) (in lakhs)</th>
								<th scope="col">Wage % (W%)</th>
								<th scope="col">Material % (M%) </th>
								<th scope="col">Person Days</th>
								<th scope="col">Implementing Agency</th>
								{type !== "data" && (
									<>
										<th scope="col">Action</th>
										<th scope="col">Action Remarks</th>
										<th scope="col">TP Case Worker Remarks</th>
										<th scope="col">ZP Case Worker Remarks</th>
									</>
								)}
							</tr>
						</thead>
						<tbody>
							{works?.map((work, index) => {
								return (
									<TableRow
										key={index}
										work={work}
										index={index}
										type={type}
										setWorkStatus={setWorkStatus}
										workStatus={workStatus}
										length={works?.length}
										pagetype={pagetype}
										setRemarks={setRemarks}
										remarks={remarks}
									/>
								);
							})}
						</tbody>
					</table>
				</CardBody>
				<div className="hljs-container">
					<h6>Total Estimated Cost: {workListDetails?.totalEc?.toFixed(5)}</h6>
					<h6>Total Wage: {workListDetails?.totalW?.toFixed(5)}</h6>
					<h6>Total Material: {workListDetails?.totalM?.toFixed(5)}</h6>
					<h6>Total NRM:{workListDetails?.totalNRM?.toFixed(5)} </h6>
					<h6>Total Person Days: {workListDetails?.totalPD}</h6>
					<h6>
						Total Wage %:{" "}
						{(
							(workListDetails?.totalW / workListDetails?.totalEc) *
							100
						).toFixed(2)}{" "}
						%
					</h6>
					<h6>
						Total Material %:{" "}
						{(
							(workListDetails?.totalM / workListDetails?.totalEc) *
							100
						).toFixed(2)}{" "}
						%
					</h6>
					<h6>
						NRM %:{" "}
						{(
							(workListDetails?.totalNRM / workListDetails?.totalEc) *
							100
						).toFixed(2)}{" "}
						%
					</h6>
				</div>
				<a
					href={`${process.env.REACT_APP_PDF_URL}/?worklist_id=${workListDetails?.id}`}
					className="m-2"
				>
					<Printer />
				</a>
			</Card>
			{page !== "confirm" ? (
				<div className="col-xl-6 m-5 ">
					<div className="col-xl-12 flex justify-items-center">
						<div className="mb-3">
							<label className="form-label">GS Date :</label>
							{new Date(workListDetails?.GS_Date).toDateString()}
						</div>
						<div className="mb-3">
							<label className="form-label" htmlFor="gs-photo">
								GS Photo:
							</label>
							<img
								className="m-3"
								alt={"GS_PHOTO"}
								width={100}
								height={100}
								src={workListDetails?.GS_Photo}
							/>
							<a
								className="m-2"
								href="#"
								onClick={() => {
									handleDownload(workListDetails?.GS_Photo);
								}}
							>
								<Download />
							</a>
							{/* <a href={workListDetails?.GS_Photo} download><Download /></a> */}
						</div>
						<div className="mb-3 d-flex justify-items-between">
							<label
								className="form-label flex justify-self-center mt-2"
								htmlFor="gs-meeting"
							>
								GS Meeting Proceeding:
							</label>
							<embed
								src={workListDetails?.GS_Proceeding}
								className="m-2"
								alt={"GS_PROCEEDING"}
								width={100}
								height={100}
							/>
							<a
								className="mt-5 ml-4"
								href="#"
								onClick={() => {
									handleDownload(workListDetails?.GS_Proceeding);
								}}
							>
								<Download />
							</a>
							{/* <img
								className="m-3"
								alt={"whatever"}
								width={100}
								height={100}
								src={workListDetails?.GS_Proceeding}
							/> */}
						</div>
						{designation === "GP-President" && (
							<>
								<>
									<div className="mb-3">
										<label htmlFor="">Gram Panchayat President Report :</label>
										<textarea
											className="d-block"
											name="gp_report"
											id=""
											cols="100"
											rows="3"
											disabled={pagetype === "complete"}
											{...(pagetype === "complete" && {
												value: workListDetails?.gp_report,
											})}
											required
											onChange={handleReportChange}
										></textarea>
									</div>
								</>
							</>
						)}
						{designation === "TP-Entry" && (
							<>
								<div className="mb-3">
									<label htmlFor="">Gram Panchayat President Report :</label>
									<textarea
										className="d-block"
										name="gp_report"
										id=""
										cols="100"
										rows="3"
										disabled
										value={workListDetails?.gp_report}
									></textarea>
								</div>
								<>
									<div className="mb-3">
										<label htmlFor="">
											Taluk Panchayat Case Worker Report :
										</label>
										<textarea
											className="d-block"
											name="tp_entryworker_report"
											id=""
											cols="100"
											rows="3"
											disabled={pagetype === "complete"}
											{...(pagetype === "complete" && {
												value: workListDetails?.tp_entryworker_report,
											})}
											onChange={handleReportChange}
										></textarea>
									</div>
								</>
							</>
						)}
						{designation === "TP-EO" && (
							<>
								<div className="mb-3">
									<label htmlFor="">Gram Panchayat President Report :</label>
									<textarea
										className="d-block"
										name="gp_report"
										id=""
										cols="100"
										rows="3"
										disabled
										value={workListDetails?.gp_report}
									></textarea>
								</div>
								<div className="mb-3">
									<label htmlFor="">Taluk Panchayat Case Worker Report :</label>
									<textarea
										className="d-block"
										name="tp_entryworker_report"
										id=""
										cols="100"
										rows="3"
										disabled
										value={workListDetails?.tp_entryworker_report}
									></textarea>
								</div>
								<>
									<div className="mb-3">
										<label htmlFor="">Taluk Panchayat EO Report :</label>
										<textarea
											className="d-block"
											name="tp_report"
											id=""
											cols="100"
											rows="3"
											disabled={pagetype === "complete"}
											{...(pagetype === "complete" && {
												value: workListDetails?.tp_report,
											})}
											onChange={handleReportChange}
										></textarea>
									</div>
								</>
								{/* <label htmlFor="">Zilla Panchayat CEO Report :</label>
								<textarea className="d-block" name="ggwp" id="" cols="100" rows="3"></textarea> */}
							</>
						)}
						{designation === "ZP-Entry" && (
							<>
								<div className="mb-3">
									<label htmlFor="">Gram Panchayat President Report :</label>
									<textarea
										className="d-block"
										name="gp_report"
										id=""
										cols="100"
										rows="3"
										disabled
										value={workListDetails?.gp_report}
									></textarea>
								</div>
								<div className="mb-3">
									<label htmlFor="">Taluk Panchayat Case Worker Report :</label>
									<textarea
										className="d-block"
										name="tp_entryworker_report"
										id=""
										cols="100"
										rows="3"
										disabled
										value={workListDetails?.tp_entryworker_report}
									></textarea>
								</div>
								<div className="mb-3">
									<label htmlFor="">Taluk Panchayat EO Report :</label>
									<textarea
										className="d-block"
										name="tp_report"
										id=""
										cols="100"
										rows="3"
										disabled
										value={workListDetails?.tp_report}
									></textarea>
								</div>
								<>
									<div className="mb-3">
										<label htmlFor="">
											Zilla Panchayat Case Worker Report :
										</label>
										<textarea
											className="d-block"
											name="zp_entryworker_report"
											id=""
											cols="100"
											rows="3"
											disabled={pagetype === "complete"}
											{...(pagetype === "complete" && {
												value: workListDetails?.zp_entryworker_report,
											})}
											onChange={handleReportChange}
										></textarea>
									</div>
								</>
								{/* <label htmlFor="">Zilla Panchayat CEO Report :</label>
								<textarea className="mb-3" name="ggwp" id="" cols="100" rows="3"></textarea> */}
							</>
						)}
						{designation === "ZP-CEO" && (
							<>
								<div className="mb-3">
									<label htmlFor="">Gram Panchayat President Report :</label>
									<textarea
										className="d-block"
										name="gp_report"
										id=""
										cols="100"
										rows="3"
										disabled
										value={workListDetails?.gp_report}
									></textarea>
								</div>
								<div className="mb-3">
									<label htmlFor="">Taluk Panchayat Case Worker Report :</label>
									<textarea
										className="d-block"
										name="tp_entryworker_report"
										id=""
										cols="100"
										rows="3"
										disabled
										value={workListDetails?.tp_entryworker_report}
									></textarea>
								</div>
								<div className="mb-3">
									<label htmlFor="">Taluk Panchayat EO Report :</label>
									<textarea
										className="d-block"
										name="tp_report"
										id=""
										cols="100"
										rows="3"
										disabled
										value={workListDetails?.tp_report}
									></textarea>
								</div>
								<div className="mb-3">
									<label htmlFor="">Zilla Panchayat Case Worker Report :</label>
									<textarea
										className="d-block"
										name="zp_entryworker_report"
										id=""
										cols="100"
										rows="3"
										disabled
										value={workListDetails?.zp_entryworker_report}
									></textarea>
								</div>
								<div className="mb-3">
									<label htmlFor="">Zilla Panchayat CEO Report :</label>
									<textarea
										className="d-block"
										name="zp_report"
										id=""
										cols="100"
										rows="3"
										disabled={pagetype === "complete"}
										{...(pagetype === "complete" && {
											value: workListDetails?.zp_report,
										})}
										onChange={handleReportChange}
									></textarea>
								</div>
							</>
						)}
					</div>
				</div>
			) : (
				<div className="col-xl-12 p-5 flex justify-content-center">
					<div className="mb-3">
						<label className="form-label">
							GS Date<span className="text-danger">*</span> :
						</label>
						{/* <DatePicker
								selected={startDate}
								onChange={handleTimeChange}
								className="form-control"
							/> */}
						<input
							type="date"
							selected={startDate}
							onChange={handleTimeChange}
							required
						/>
					</div>
					<div className="mb-3">
						<label className="form-label" htmlFor="gs-photo">
							GS Photo <span className="text-danger">*</span> :
						</label>
						<input
							type="file"
							className="form-control w-25"
							id="gs-photo"
							name="GS_Photo"
							onChange={handleFileChange}
							required
						/>
						<span value={workListDetails?.GS_Photo}>
							{workListDetails?.GS_Photo}
						</span>
					</div>
					<div className="">
						<label className="form-label" htmlFor="gs-meeting">
							GS Meeting Proceeding <span className="text-danger">*</span> :
						</label>
						<input
							type="file"
							className="form-control w-25"
							id="gs-meeting"
							name="GS_Proceeding"
							onChange={handleFileChange}
							required
						/>
						<span value={workListDetails?.GS_Proceeding}>
							{workListDetails?.GS_Proceeding}
						</span>
					</div>
				</div>
			)}
			{/* {(workListDetails?.totalW / workListDetails?.totalEc) * 100 <= 60 ? (
				<div className="alert alert-danger">
					<strong>Please Re-enter</strong> Wage Percentage is lesser than 60.
				</div>
			) : null} */}
			{(workListDetails?.totalM / workListDetails?.totalEc) * 100 >= 40.01 ? (
				<div className="alert alert-danger">
					<strong>Please Re-enter</strong> Material Percentage is greater than
					40.
				</div>
			) : null}
			{(workListDetails?.totalNRM / workListDetails?.totalEc) * 100 <= 60 ? (
				<div className="alert alert-danger">
					<strong>Please Re-enter</strong> NRM Percentage is lesser than 65.
				</div>
			) : null}
		</div>
	);
};
