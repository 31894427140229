import React, { useEffect, useContext, useState, useRef } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";
import { changepassword } from "../../config/api.service";
import { useSearchParams } from "react-router-dom";
import Loading from "../../components/Loader.jsx";

function ChangePassword() {
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState("password");
	const [showPassword1, setShowPassword1] = useState("password");
	const context = useContext(AppSettings);
	const [searchParams, setSearchParams] = useSearchParams();
	const [usertype, setUserType] = useState("Entry-User");
	const [errorMessage, setErrorMessage] = useState(false);
	const [successMessage, setSuccessMessage] = useState(false);
	const [successText, setSuccessText] = useState("");
	const [loading, setLoading] = useState(false);
	const pass = useRef();
	const pass1 = useRef();

	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentClass("p-0");

		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass("");
		};

		// eslint-disable-next-line
	}, []);

	const showPass = () => {
		if (showPassword === "password") {
			setShowPassword("text");
		} else {
			setShowPassword("password");
		}
	};
	const showPass1 = () => {
		if (showPassword1 === "password") {
			setShowPassword1("text");
		} else {
			setShowPassword1("password");
		}
	};

	const changePassword = async (event) => {
		event.preventDefault();
		try {
			if (event.target.password.value === event.target.confpassword.value) {
				setLoading(true);
				const userDetails = await changepassword({
					designation: searchParams.get("userDesignation"),
					password: event.target.password.value,
					user_id: searchParams.get("userid"),
				});
				setLoading(false);
				if (userDetails?.affected === 1) {
					setErrorMessage(false);
					setSuccessMessage(true);
				} else {
					setSuccessMessage(false);
					setErrorMessage(true);
				}
			} else {
				setSuccessMessage(false);
				setErrorMessage(true);
			}
		} catch (e) {
			console.log(e.response);
		}
	};

	function handleChange(e) {
		const { nodeName, value } = e.target;

		if (nodeName === "INPUT") {
			setUserType(value);
		}
	}

	return (
		<div className="m-5 d-flex justify-content-between">
			{loading === true ? <Loading /> : null}

			<div className="login-content border p-3">
				<form onSubmit={changePassword}>
					<h1 className="text-center">Change Password</h1>
					<div className="text-white text-opacity-50 text-center mb-4">
						Please enter a new secure password.
					</div>
					{errorMessage && (
						<div className="text-center alert alert-danger">
							passwords dont match
						</div>
					)}
					{successMessage && (
						<div className="text-center alert alert-success">
							Successfully changed password
						</div>
					)}

					{/* <div className="mb-3">
                        <div className="d-flex">
                            <label className="form-label">
                                Password <span className="text-danger">*</span>
                            </label>
                        </div>
                        <input
                            type="password"
                            className="form-control form-control-lg bg-white bg-opacity-5"
                            placeholder=""
                            name="password"
                        />
                    </div>
                    <div className="mb-3">
                        <div className="d-flex">
                            <label className="form-label">
                                Confirm Password <span className="text-danger">*</span>
                            </label>
                        </div>
                        <input
                            type="password"
                            className="form-control form-control-lg bg-white bg-opacity-5"
                            placeholder=""
                            name="confirm_password"
                        />
                    </div> */}
					<div class="mb-3">
						<div className="d-flex">
							<label className="form-label">
								Password <span className="text-danger">*</span>
							</label>
						</div>
						<div className="input-group">
							<input
								ref={pass}
								type={showPassword}
								className="form-control form-control-lg bg-white bg-opacity-5"
								name="password"
								placeholder="Enter New Password"
								required
							/>
							<span role="button" class="input-group-text" onClick={showPass}>
								show
							</span>
						</div>
					</div>
					<div class="input-group mb-3">
						<div className="d-flex">
							<label className="form-label">
								Confirm Password <span className="text-danger">*</span>
							</label>
						</div>
						<div className="input-group">
							<input
								ref={pass1}
								type={showPassword1}
								className="form-control form-control-lg bg-white bg-opacity-5"
								name="confpassword"
								placeholder="Re-Enter New Password"
								required
							/>
							<span role="button" class="input-group-text" onClick={showPass1}>
								show
							</span>
						</div>
					</div>
					{!successMessage && (
						<button
							type="submit"
							className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3"
						>
							Reset Password
						</button>
					)}
				</form>
			</div>
		</div>
	);
}

export default ChangePassword;
