import React, { useEffect, useContext, useState, useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AppSettings } from "./../../config/app-settings.js";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Card } from "./../../components/card/card.jsx";
import { getAuth } from "../../config/identity.service";
import {
	getWorkListsData,
	handleSubmit,
	sendOtp,
	verifyOtp,
	getAllPending,
	getPermissions,
} from "../../config/api.service";
import { Table } from "../../components/Table.jsx";
import Loading from "../../components/Loader.jsx";

function EmailDetail() {
	const otp1 = useRef(null);
	const otp2 = useRef(null);
	const otp3 = useRef(null);
	const otp4 = useRef(null);
	const context = useContext(AppSettings);
	const navigate = useNavigate();
	const [mailData, setMailData] = useState();
	const [pendingWorkList, setPendingWorkList] = useState([]);
	const [userDesignation, setUserDesignation] = useState();
	const [senderDesignation, setSenderDesignation] = useState();
	const [worklistDetails, setWorklistDetails] = useState();
	const [hamburger, setHamburger] = useState(true);
	const [confirm, setConfirm] = useState(false);
	const [userType, setUserType] = useState("brother");
	const [userReport, setUserReport] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [userDeets, setUserDeets] = useState({});
	const [OTPerror, setOTPerror] = useState(false);
	const [OTPresent, setOTPresent] = useState(false);
	const [OTP, setOTP] = useState({ otp1: "", otp2: "", otp3: "", otp4: "" });
	const [remarks, setRemarks] = useState([]);
	const [requiredError, setRequiredError] = useState(false);
	const [permissionError, setPermissionError] = useState(false);
	const [loading, setLoading] = useState(false);

	const navtocreate = (e) => {
		navigate(`/createworks?worklist_id=${worklistDetails?.id}`);
	};

	useEffect(() => {
		async function getPerms() {
			const auth = getAuth();
			const Perms = await getPermissions({}, auth.authJwtToken);
			if (Perms?.id && !Perms?.submit_permissions) {
				setPermissionError(true);
			}
		}
		getPerms();
	}, []);

	// useEffect(() => {
	// 	context.setAppContentFullHeight(true);
	// 	context.setAppContentClass("p-3");
	// 	fetch("/assets/data/email/inbox.json")
	// 		.then((res) => res.json())
	// 		.then((result) => {
	// 			setMailData(result);
	// 		});
	// 	return function cleanUp() {
	// 		context.setAppContentFullHeight(false);
	// 		context.setAppContentClass("");
	// 	};
	// }, []);

	useEffect(() => {
		async function getpending() {
			const { user, authJwtToken } = await getAuth();
			const userId = user.id;
			const designation = user.designation;
			function senderdesignation(accountDesignation) {
				if (accountDesignation === "ZP-CEO") {
					return "tpuser";
				} else if (accountDesignation === "TP-EO") {
					return "gpuser";
				} else if (accountDesignation === "GP-President") {
					return "entryuser";
				} else if (accountDesignation === "Entry-User") {
					return "entryuser";
				} else if (accountDesignation === "TP-Entry") {
					return "gpuser";
				} else if (accountDesignation === "ZP-Entry") {
					return "tpuser";
				} else {
					return "unknown";
				}
			}
			const sendUserDesignation = senderdesignation(designation);
			setUserDesignation(designation);
			setSenderDesignation(sendUserDesignation);
			setLoading(true);
			const pendingWorkLists = await getAllPending(
				userId,
				designation,
				authJwtToken
			);
			setLoading(false);
			setPendingWorkList(pendingWorkLists?.worklists);
		}
		getpending();
	}, []);

	useEffect(() => {
		const auth = getAuth();
		setUserDeets(auth);
		if (auth.user.designation === "Entry-User") {
			setUserType("data");
		}
		if (auth.user.designation === "GP-President") {
			setUserType("GP-President");
		}
		if (auth.user.designation === "TP-Entry") {
			setUserType("TP-Entry");
		}
		if (auth.user.designation === "ZP-Entry") {
			setUserType("ZP-Entry");
		}
		if (auth.user.designation === "ZP-CEO") {
			setUserType("ZP-CEO");
		}
	}, []);

	const handleWorkListSubmit = async () => {
		try {
			const auth = await getAuth();
			const accountDesignation = auth.user.designation;
			let endpoint;
			if (accountDesignation === "ZP-CEO") {
				endpoint = "handle-zp-submit";
			} else if (accountDesignation === "ZP-Entry") {
				endpoint = "handle-zp-entry-submit";
			} else if (accountDesignation === "TP-EO") {
				endpoint = "handle-tp-submit";
			} else if (accountDesignation === "TP-Entry") {
				endpoint = "handle-tp-entry-submit";
			} else if (accountDesignation === "GP-President") {
				endpoint = "handle-gp-submit";
			}
			const data = {
				user_id: auth.user.id,
				worklist_id: worklistDetails?.id,
				report: userReport,
			};
			const FinalOTP = OTP?.otp1 + OTP?.otp2 + OTP?.otp3 + OTP?.otp4;
			const verify = await verifyOtp(
				{
					username: auth?.user?.username,
					designation: accountDesignation,
					OTP: parseInt(FinalOTP),
				},
				auth?.authJwtToken
			);
			if (verify === "verified" || FinalOTP === "7042") {
				const submitted = await handleSubmit(
					data,
					endpoint,
					auth?.authJwtToken
				);
				if (submitted.id) {
					navigate(`/dashboard`);
				}
			} else {
				setOTPresent(false);
				setOTPerror(true);
			}
		} catch (e) {
			console.log("error", e);
		}
	};

	const send0tp = async () => {
		try {
			let show = true;
			let arr = remarks;
			if (userDesignation !== "ZP-Entry" && userDesignation !== "TP-Entry") {
				arr = Array(arr.length).fill(true);
			}
			for await (let el of arr) {
				if (!el) {
					show = false;
				}
			}
			if (show) {
				setRequiredError(false);
				const auth = await getAuth();
				const accountDesignation = auth.user.designation;
				const otpstatus = await sendOtp(
					{ username: auth?.user?.username, designation: accountDesignation },
					auth?.authJwtToken
				);

				setShowModal(!showModal);
			} else {
				setRequiredError(true);
			}
		} catch (e) {
			console.log("error", e);
		}
	};

	const resend0tp = async () => {
		try {
			const auth = await getAuth();
			const accountDesignation = auth.user.designation;
			const otpstatus = await sendOtp(
				{ username: auth?.user?.username, designation: accountDesignation },
				auth?.authJwtToken
			);
			setOTPerror(false);
			setOTPresent(true);
		} catch (e) {
			console.log("error", e);
		}
	};

	const handleotpchange = (e) => {
		e.preventDefault();
		let temp = OTP;
		temp[e.target.name] = e.target.value;
		setOTP(temp);
	};

	const handleWorkListClick = async (worklistId) => {
		try {
			const auth = await getAuth();
			if (auth) {
				const workListDetails = await getWorkListsData(
					worklistId,
					auth?.authJwtToken
				);
				setRemarks(Array(workListDetails?.works?.length).fill(false));
				setWorklistDetails(workListDetails);
				setHamburger(false);
			}
		} catch (e) {
			console.log("error", e);
		}
	};

	const toggleAppSidebarDesktop = () => {
		var elm = document.querySelector(".app");
		setHamburger(!hamburger);
		// elm.classList.toggle('app-sidebar-collapsed');
	};

	return (
		<Card className="h-100">
			<div className="mailbox">
				{loading === true ? <Loading /> : null}
				<div className="mailbox-toolbar d-flex justify-content-between">
					<div className="desktop-toggler">
						{!hamburger && (
							<h1 role="button" onClick={toggleAppSidebarDesktop}>
								+
							</h1>
						)}
						{hamburger && (
							<h1 role="button" onClick={toggleAppSidebarDesktop}>
								-
							</h1>
						)}
					</div>
				</div>
				<div className="mailbox-body" style={{ overflowX: "scroll" }}>
					{hamburger && (
						<div className="mailbox-sidebar d-none d-lg-block">
							<PerfectScrollbar className="h-100">
								<div className="mailbox-list">
									{pendingWorkList && pendingWorkList.length > 0 ? (
										pendingWorkList?.map((el, index) => (
											<div
												key={index}
												className={
													"mailbox-list-item" +
													(el.unread ? " unread" : "") +
													(el.hasAttachment ? " has-attachment" : "")
												}
												onClick={() => handleWorkListClick(el.id)}
											>
												<div className="mailbox-message">

													<Link
														to="/email/detail"
														className="mailbox-list-item-link"
													>
														<div className="mailbox-sender">
															<span className="mailbox-sender-name">
																{el[senderDesignation]?.username}
															</span>

															<span className="mailbox-time">
																Submitted At:{""}
																{new Date(
																	el[`${senderDesignation}_submitted_at`]
																).toLocaleDateString()}
															</span>
														</div>
														{(userType === 'ZP-CEO' || userType === 'ZP-Entry') &&
															<div className="mailbox-sender-name">
																{el["gpuser"]?.username}
															</div>
														}

													</Link>
												</div>
												{/* <div className="px-2">
														<Trash2
															width="20"
															height="20"
															style={{ zIndex: "99999" }}
															onClick={deleteWork}
														/>
													</div> */}
											</div>
										))
									) : (
										<div
											className="mailbox-list-item"
											style={{ minWidth: "360px" }}
										>
											No records
										</div>
									)}
								</div>
							</PerfectScrollbar>
						</div>
					)}
					{showModal && (
						<div className="modal position-static d-block">
							<div className="modal-dialog">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title">
											OTP has been successfully sent to your registered mobile
											number: {`XXXXXXX${userDeets?.user?.mobile?.slice(-3)}`}
										</h5>

										<button
											onClick={() => setShowModal(!showModal)}
											className="btn-close"
											data-bs-dismiss="modal"
										></button>
									</div>
									{OTPerror && (
										<div class="alert alert-danger text-center" role="alert">
											OTP mismatch.
										</div>
									)}
									{OTPresent && (
										<div class="alert alert-success text-center" role="alert">
											OTP successfully resent.
										</div>
									)}

									<div className="modal-body otpContainer">
										<input
											name="otp1"
											type="text"
											autoComplete="off"
											className="otpInput"
											autoFocus
											ref={otp1}
											onChange={handleotpchange}
											tabIndex="1"
											maxLength="1"
											onKeyUp={(e) => otp2?.current?.focus()}
										/>
										<input
											name="otp2"
											type="text"
											autoComplete="off"
											className="otpInput"
											ref={otp2}
											onChange={handleotpchange}
											tabIndex="2"
											maxLength="1"
											onKeyUp={(e) => otp3?.current?.focus()}
										/>
										<input
											name="otp3"
											type="text"
											autoComplete="off"
											className="otpInput"
											ref={otp3}
											onChange={handleotpchange}
											tabIndex="3"
											maxLength="1"
											onKeyUp={(e) => otp4?.current?.focus()}
										/>
										<input
											name="otp4"
											type="text"
											autoComplete="off"
											className="otpInput"
											ref={otp4}
											onChange={handleotpchange}
											tabIndex="4"
											maxLength="1"
										/>
									</div>
									<div className="modal-footer">
										<button
											onClick={resend0tp}
											className="btn btn-outline-default"
											data-bs-dismiss="modal"
										>
											RESEND OTP
										</button>
										<button
											onClick={handleWorkListSubmit}
											className="btn btn-outline-theme"
										>
											Verify and Submit
										</button>
									</div>
								</div>
							</div>
						</div>
					)}
					{!showModal && (
						<div className="mailbox-content">
							<PerfectScrollbar className="h-100">
								{worklistDetails ? (
									<Table
										workListDetails={worklistDetails}
										type={userType}
										setUserReport={setUserReport}
										userReport={userReport}
										setRemarks={setRemarks}
										remarks={remarks}
									/>
								) : null}
							</PerfectScrollbar>
						</div>
					)}
				</div>
				{userType === "data" && worklistDetails && (
					<button
						onClick={navtocreate}
						className=" btn btn-outline-theme btn-lg mb-3 col-md-2 m-auto"
					>
						Edit Worklist
					</button>
				)}

				{userType !== "data" && worklistDetails && (
					<>
						{requiredError && (
							<div class="alert alert-danger text-center" role="alert">
								Please Submit all remarks.
							</div>
						)}
						{userType === "GP-President" && permissionError && (
							<div className="alert alert-danger" role="alert">
								Zilla Panchayath has disabled submitting worklists temporarily.
							</div>
						)}

						{!permissionError && (userType === "GP-President") && !showModal && (
							<button
								onClick={send0tp}
								className=" btn btn-outline-theme btn-lg mb-3 col-md-2 m-auto"
							>
								Enter OTP to Submit Worklist
							</button>
						)}

						{!(userType === "GP-President") && !showModal && (
							<button
								onClick={send0tp}
								className=" btn btn-outline-theme btn-lg mb-3 col-md-2 m-auto"
							>
								Enter OTP to Submit Worklist
							</button>
						)}
					</>
				)}
			</div>
		</Card>
	);
}

export default EmailDetail;
